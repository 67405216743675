import { deleteInfoEntidad } from '../../store/infoEntidad.js'
import { deleteInfoGeneral } from '../../store/infoGeneral.js'
import { deleteInfoUsuario } from '../../store/infoUsuario.js'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'


const Logout = ({ setIsLoggedIn }) => {
  const infoUsuario = useSelector((state) => state.infoUsuario.value);
  const role = infoUsuario.role.name;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(deleteInfoEntidad());
  dispatch(deleteInfoGeneral());
  dispatch(deleteInfoUsuario());
  localStorage.removeItem('usuario');
  localStorage.removeItem('general');
  localStorage.removeItem('entidad');
  setIsLoggedIn(false);
  console.log(infoUsuario);
  if (role === 'PATIENT' || localStorage.getItem('was_patient') == 'true') {
    navigate("/misResultados");
  } else {
    navigate("/");
  }
  setTimeout(() => {
    window.location.reload()
  }, 100)


  return (

    <>
      <p>Sad to see you go</p>
    </>
  )
};

export default Logout;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Tabla from '../../components/Tabla2.js';
import Api from '../../api.js';

const Modulo = () => {
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    let infoUsuario = useSelector((state) => state.infoUsuario.value);
    let [cli, setCli] = useState([]);
    let api = new Api();

    //Configurables---------------------------------------------------------------------------
    const titulo = "Doctores";
    const icono = "fa-user-doctor";
    let modals = [];
    if (infoUsuario.role.name === 'CLINIC') {
        modals = [
            {
                titulo: "Nuevo Doctor",
                endpoint: "doctor",
                buttonText: "Guardar",
                urlCall: "new",
                method: "POST",
                fields: [
                    {
                        label: "row", elements: [
                            { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                            { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                        ]
                    },
                    {
                        label: "row", elements: [
                            { label: "Tipo Documento", name: "documentTypeId", element: "select", required: false, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                            { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: false, isNumber: false },
                        ]
                    },
                    {
                        label: "row", elements: [
                            { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
                            { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                        ]
                    },
                    {
                        label: "row", elements: [
                            { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
                            { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                        ]
                    }
                ],
                dataMask: null
            }
        ];
    } else {
        modals = [
            {
                titulo: "Nuevo Doctor",
                endpoint: "doctor",
                buttonText: "Guardar",
                urlCall: "new",
                method: "POST",
                fields: [
                    {
                        label: "row", elements: [
                            { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                            { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                        ]
                    },
                    {
                        label: "row", elements: [
                            { label: "Tipo Documento", name: "documentTypeId", element: "select", required: false, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                            { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: false, isNumber: false },
                        ]
                    },
                    {
                        label: "row", elements: [
                            { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
                            { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                        ]
                    },
                    {
                        label: "row", elements: [
                            { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
                            { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                        ]
                    },
                    {
                        label: "row", elements: [
                            { label: "Clinica Perteneciente", element: "subtitle" },
                        ]
                    },
                    {
                        label: "row", elements: [
                            { label: "Clinica", name: "clinicId", element: "select", multiple: true, required: false, isNumber: true, options: cli }
                        ]
                    }
                ],
                dataMask: null
            }
        ];
    }

    const tabla1 = {
        endpoint: "doctor",
        titulos: [{ texto: "Nombre", name: "firstName" }, { texto: "Apellidos", name: "lastName" }, { texto: "Tipo Documento", name: "documentTypeId" }, { texto: "Documento", name: "documentNumber" }, { texto: "Email", name: "email" }, { texto: "Teléfono", name: "phone" }, { name: "status" }],
        columnas: [{ prop: "firstName", type: "link", url: "profile/$id$" }, { prop: "lastName", type: "link", url: "profile/$id$" }, { prop: "documentTypeId", type: "text", format: (x) => { let selectTxt = infoGeneral.documentTypeSelectOptions.filter((d) => { return d.value == x }); if (selectTxt.length > 0) { return selectTxt[0].text } } }, { prop: "documentNumber", type: "text" }, { prop: "email", type: "text" }, { prop: "phone", type: "text" }, { type: "status", url: "doctor" }]
    }

    //Funciones Basicas-----------------------------------------------------------------------
    let { urlCall } = useParams();
    let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
    const navigate = useNavigate();
    let location = useLocation();

    const openModal = (modalNumber) => {
        navigate(modals[modalNumber].urlCall);
    }

    let [forceRender, setForceRender] = useState(0);
    const sendToRender = () => {
        setForceRender(forceRender + 1);
    };

    useEffect(() => {
        setModalsVisibility(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
        traerClinicas();
    }, [location]);

    async function traerClinicas() {
        var resp = await api.get("clinic?paginate=2000", infoUsuario.token);

        if (!resp.error) {
            let clini = resp.info.map((c) => {
                return { value: c.id, text: c.clinicUsers[0].tradename }
            });

            setCli(clini);
        }

    }

    return (
        <>
            {modals.map((modal, index) => {
                return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender} />
            })}



            <div className="pageHeading">
                <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
                <Link to="new">+</Link>
            </div>



            <Tabla info={tabla1} forceRender={forceRender} />

        </>
    );

};

export default Modulo;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Tabla from '../../components/Tabla2.js';

const Modulo = () => {
    let infoGeneral = useSelector((state) => state.infoGeneral.value);

    //Configurables---------------------------------------------------------------------------
    const titulo = "Clinicas";
    const icono = "fa-hospital";
    const modals = [
        {
            titulo: "Nueva Clinica",
            endpoint: "clinic",
            buttonText: "Guardar",
            urlCall: "new",
            method: "POST",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Información Clínica", element: "subtitle" }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "NIT", name: "rut", element: "input", type: "text", required: false, isNumber: false },
                        { label: "Nombre Legal", name: "tradename", element: "input", type: "text", required: false, isNumber: false }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Representante Legal", element: "subtitle" }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo Documento", name: "documentTypeId", element: "select", required: false, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                        { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: false, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
                        { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
                        { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                    ]
                }
            ],
            dataMask: null
        },
    ];
    const tabla1 = {
        endpoint: "clinic",
        titulos: [
            { texto: "Nombre Legal", name: "clinicUsers" },
            { texto: "NIT", name: "clinicUsers" },
            { texto: "Nombre", name: "firstName" },
            { texto: "Apellidos", name: "lastName" },
            { texto: "Email", name: "email" },
            { texto: "Teléfono", name: "phone" },
            { name: "status" }],
        columnas: [
            { prop: "clinicUsers", type: "link", url: "profile/$id$", format: (x) => { return x[0].tradename; } },
            { prop: "clinicUsers", type: "text", format: (x) => { return x[0].rut; } },
            { prop: "firstName", type: "text" },
            { prop: "lastName", type: "text" },
            { prop: "email", type: "text" },
            { prop: "phone", type: "text" },
            { type: "status", url: "clinic" }]
    }

    //Funciones Basicas-----------------------------------------------------------------------
    let { urlCall } = useParams();
    let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
    const navigate = useNavigate();
    let location = useLocation();

    const openModal = (modalNumber) => {
        navigate(modals[modalNumber].urlCall);
    }

    let [forceRender, setForceRender] = useState(0);
    const sendToRender = () => {
        setForceRender(forceRender + 1);
    };


    useEffect(() => {
        setModalsVisibility(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
    }, [location]);


    return (
        <>
            {modals.map((modal, index) => {
                return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender} />
            })}



            <div className="pageHeading">
                <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
                <Link to="new">+</Link>
            </div>



            <Tabla info={tabla1} forceRender={forceRender} />

        </>
    );

};

export default Modulo;
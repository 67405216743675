import { showLoading, globalLoading } from 'react-global-loading';
const Api = class {
    constructor() {
        this.url = process.env.REACT_APP_BASE_URL;
    }

    async get(endpoint, token = "") {
        let answ = {
            message: "",
            info: "",
            error: ""
        };
        if (
            endpoint.indexOf('order/admin/view') > -1
        ) {
            globalLoading.show();
        }
        let x = await fetch(this.url + '/' + endpoint, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + token
            }),
        }).then(async response => {
            if (response.status === 200) {
                let r = await response.json();
                answ.info = r.body;
                answ.pages = r.pages;
                answ.error = false;
                return answ;
            } else {
                let r = await response.json();
                if (r.status == 401) {
                    window.location.href = "/logout";
                }
                answ.info = '';
                answ.pages = 0;
                answ.error = r.body[0].error;

                return answ;
            }
        }).catch(error => {
            answ.error = error;
            return answ;
        }).finally(() => {
            if (
                endpoint.indexOf('order/admin/view') > -1
            ) {
                globalLoading.hide();
            }
        })

        return x;
    }

    async post(endpoint, data, token) {
        let answ = {
            message: "",
            info: "",
            error: ""
        };
        globalLoading.show();
        let x = await fetch(this.url + '/' + endpoint, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + token
            }),
            method: 'POST',
            body: JSON.stringify(data)
        }).then(async function (response) {
            if (response.ok) {
                let r = await response.json();
                answ.info = r.body;
                answ.error = false;
                return answ;
            } else {
                let r = await response.json();
                if (r.status == 401) {
                    window.location.href = "/logout";
                }
                if (r.status == 400) {
                    answ.error = r.message;
                    if (Array.isArray(r.body) && r.body[0] && r.body[0].error) {
                        answ.error += " " + r.body[0].error;
                    }
                } else {
                    answ.error = r.body[0].error;
                    if (Array.isArray(r.body) && r.body[0] && r.body[0].error) {
                        answ.error += " " + r.body[0].error;
                    }
                }
                answ.info = '';
                globalLoading.hide();
                return answ;
            }
        }).catch(function (error) {
            answ.error = error;
            globalLoading.hide();
            return answ;
        }).finally(() => {
            globalLoading.hide();
        });

        return x;
    }

    async postFile(endpoint, files, token, isDicom = false) {
        let answ = {
            message: "",
            info: "",
            error: ""
        };
        globalLoading.show();

        let formData = new FormData();

        //formData.append('attachment', files[0]);
        [...files].forEach((file) => {
            formData.append('attachment', file);
        });
        formData.append('dicom', isDicom)

        //formData.append('attachment', files);
        let x = await fetch(this.url + '/' + endpoint, {
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + token
            }),
            method: 'POST',
            body: formData
        }).then(async function (response) {
            if (response.ok) {
                let r = await response.json();
                answ.info = r.body;
                answ.error = false;
                return answ;
            } else {
                let r = await response.json();
                if (r.status == 401) {
                    window.location.href = "/logout";
                }
                if (r.status == 400) {
                    answ.error = r.message;
                } else {
                    answ.error = r.body[0].error;
                }
                answ.info = '';
                globalLoading.hide();
                return answ;
            }
        }).catch(function (error) {
            answ.error = error;
            globalLoading.hide();
            return answ;
        }).finally(() => {
            globalLoading.hide();
        });

        return x;
    }

    async put(endpoint, data, token) {
        let answ = {
            message: "",
            info: "",
            error: ""
        };
        globalLoading.show();
        let x = await fetch(this.url + '/' + endpoint, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + token
            }),
            method: 'PUT',
            body: JSON.stringify(data)
        }).then(async function (response) {
            if (response.ok) {
                let r = await response.json();
                answ.info = r.body;
                answ.error = false;
                return answ;
            } else {
                let r = await response.json();
                if (r.status == 401) {
                    window.location.href = "/logout";
                }

                if (r.status == 400) {
                    answ.error = r.message;
                    if (Array.isArray(r.body) && r.body[0] && r.body[0].error) {
                        answ.error += " " + r.body[0].error;
                    }
                } else {
                    answ.error = r.body[0].error;
                    if (Array.isArray(r.body) && r.body[0] && r.body[0].error) {
                        answ.error += " " + r.body[0].error;
                    }
                }
                answ.info = '';
                globalLoading.hide();
                return answ;
            }
        }).catch(function (error) {
            answ.error = error;
            globalLoading.hide();
            return answ;
        }).finally(() => {
            globalLoading.hide();
        });

        return x;
    }

    async delete(endpoint, token = "") {
        let answ = {
            message: "",
            info: "",
            error: ""
        };
        globalLoading.show();
        let x = await fetch(this.url + '/' + endpoint, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + token
            }),
        }).then(async response => {
            if (response.status === 200) {
                let r = await response.json();
                answ.info = r.body;
                answ.pages = r.pages;
                answ.error = false;
                return answ;
            } else {
                let r = await response.json();
                if (r.status == 401) {
                    window.location.href = "/logout";
                }

                if (r.status == 400) {
                    answ.error = r.message;
                } else {
                    answ.error = r.body[0].error;
                }
                answ.info = '';
                answ.pages = 0;
                globalLoading.hide();
                return answ;
            }
        }).catch(error => {
            answ.error = error;
            globalLoading.hide();
            return answ;
        }).finally(() => {
            globalLoading.hide();
        });

        return x;
    }
}



export default Api;
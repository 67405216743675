import React, { useRef, useState, useEffect } from 'react';
import Api from '../../api.js';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Modal from '../../components/Modal.js';

import { setError, deleteError } from '../../store/infoGeneral.js'

const Nuevo = ({ instructions }) => {

    let api = new Api();

    const titulo = "Orden";
    const icono = "fa-file";

    const dispatch = useDispatch();
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    let infoUsuario = useSelector((state) => state.infoUsuario.value);
    let location = useLocation();
    let [listaPacientes, setListaPacientes] = useState([]);
    let [listaClinicas, setListaClinicas] = useState([]);
    let [listaDoctores, setListaDoctores] = useState([]);
    let [listaDoctoresPorClinica, setListaDoctoresPorClinica] = useState([]);
    let [listaSedes, setListaSedes] = useState([]);
    let [listaTipoExamenes, setListaTipoExamenes] = useState([]);
    let [showDoctor, setShowDoctor] = useState(false);
    let [paciente, setPacienteState] = useState("");
    let [cliente, setClienteState] = useState("");
    let [doctor, setDoctorState] = useState("");
    let [sede, setSedeState] = useState("");
    let [tipoExamen, setTipoExamen] = useState("");
    let [comments, setCommentsState] = useState("");
    let [examenes, setExamenes] = useState([]);

    //Referencias
    let obj = useRef([]);

    let precioRef = useRef();
    let cantidadRef = useRef();
    const navigate = useNavigate();

    const setPatientAfterAdding = (x) => {
        var patient = { label: x.info.firstName + " " + x.info.lastName, value: x.info.id }
        localStorage.setItem("paciente", JSON.stringify(patient));
    }

    const setClientAfterAdding = async (x) => {
        let resp = await api.get("clinic?paginate=2000", infoUsuario.token);

        if (!resp.error) {
            let chosenClinic = resp.info.filter((clin) => {
                return clin.id == x.info.id;
            });

            let clinic = { label: "Clinica: " + chosenClinic[0].clinicUsers[0].tradename, value: "c-" + chosenClinic[0].id }
            localStorage.setItem("cliente", JSON.stringify(clinic));
        }
    }

    const opcionEnBlancoCliente = [{ label: "Selecciona un Cliente ", value: "" }];
    const opcionEnBlancoDoctor = [{ label: "Selecciona un Doctor ", value: "" }];
    const documents = JSON.parse(localStorage.general).documentType;

    async function getStorageOptions(searchTerm, callback) {
        const filtered = await traerPacientes(searchTerm);
        callback(filtered.map((x) => {
            return {
                label: x.firstName + ' ' + x.lastName + ' (' + documents.find(d => d.id === x.documentTypeId)?.name + ': ' + x.documentNumber + ')', value: x.id
            }
        }));
    }
    /*const optionsPacientes = listaPacientes.map((x) => {
        return {
            label: <span dangerouslySetInnerHTML={
                {
                    __html: `${x.firstName} ${x.lastName}<br>${documents.find(d => d.id === x.documentTypeId).name}: ${x.documentNumber}`
                }
            } />, value: x.id
        }
    });*/

    const optionsPacientes = listaPacientes.map((x) => {
        return {
            label: x.firstName + ' ' + x.lastName + ' (' + documents.find(d => d.id === x.documentTypeId).name + ': ' + x.documentNumber + ')', value: x.id
        }
    });

    const optionsClientes = [...opcionEnBlancoCliente, ...listaClinicas.map((x) => { return { label: "Clinica: " + x.clinicUsers[0].tradename, value: "c-" + x.id } }), ...listaDoctores.map((x) => { return { label: "Doctor: " + x.firstName + " " + x.lastName, value: "d-" + x.id } })];

    let optionsDoctoresPorClinica = [...opcionEnBlancoDoctor, ...listaDoctoresPorClinica.map((x) => { return { label: x.firstName + " " + x.lastName, value: x.id } })];

    const optionsSedes = listaSedes.map((x) => { return { label: x.name, value: x.id } });

    const optionsTipoExamen = listaTipoExamenes.map((x) => { return { label: x.name, value: x.id } });

    const modals = [
        {
            titulo: "Nuevo Paciente",
            endpoint: "patient",
            buttonText: "Guardar",
            urlCall: "newPatient",
            method: "POST",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo Documento", name: "documentTypeId", element: "select", required: true, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                        { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: true, isNumber: false },
                        { label: "Sexo", name: "gender", element: "select", required: true, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
                        { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                    ]
                }
            ],
            dataMask: null,
            afterSubmitting: setPatientAfterAdding
        },
        {
            titulo: "Nueva Clinica",
            endpoint: "clinic",
            buttonText: "Guardar",
            urlCall: "newClinic",
            method: "POST",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Información Clínica", element: "subtitle" }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "RUT", name: "rut", element: "input", type: "text", required: false, isNumber: false },
                        { label: "Nombre Legal", name: "tradename", element: "input", type: "text", required: false, isNumber: false }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Representante Legal", element: "subtitle" }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo Documento", name: "documentTypeId", element: "select", required: false, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                        { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: false, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
                        { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
                        { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                    ]
                }
            ],
            dataMask: null,
            afterSubmitting: setClientAfterAdding
        },
        {
            titulo: "Nuevo Doctor",
            endpoint: "doctor",
            buttonText: "Guardar",
            urlCall: "newDoctor",
            method: "POST",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo Documento", name: "documentTypeId", element: "select", required: false, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                        { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: false, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
                        { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
                        { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Clinica Perteneciente", element: "subtitle" },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Clinica", name: "clinicId", element: "select", multiple: true, required: false, isNumber: true, options: optionsClientes.filter((x) => { return x.value.includes("c") }).map((x => { return { value: x.value.split("-").pop(), text: x.label }; })) }
                    ]
                }
            ],
            dataMask: null
        }
    ];

    let { urlCall } = useParams();
    let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));


    useEffect(() => {
        if (infoUsuario.token !== null) {
            traerPacientes();
            traerClinicas();
            traerDoctores();
            traerSedes();
            traerTipoExamenes();
            setPaciente("");
            setCliente("");
            setDoctor("");
            setSede("");
            setComments("");
        }
    }, [location, infoUsuario]);

    useEffect(() => {
        setDoctor("");
        if (cliente !== "") {
            if (cliente.value.substring(0, 1) == "c") {
                traerDoctoresPorClinica();
                setShowDoctor(true);
            } else {
                setShowDoctor(false);
            }
        }
    }, [cliente])

    useEffect(() => {
        limpiarExamenes();
    }, [doctor])

    useEffect(() => {
        async function tr() {
            if (tipoExamen == "") {
                setTipoExamen("");
                return;
            }
            /*if(cliente == ""){
                dispatch(setError("Debe seleccionar un cliente primero"));
                setTipoExamen("");
                return;
            }*/

            dispatch(deleteError());
            if (cliente !== "") {
                let clientId = cliente.value.slice(2);
                var info = await api.get("file-type/" + tipoExamen.value + "/client-price/" + clientId, infoUsuario.token);
            } else {
                var info = await api.get("file-type/" + tipoExamen.value, infoUsuario.token);
            }
            if (!info.error) {
                //setCantidad(1);
                cantidadRef.current.value = 1;
                if (info.info) {
                    precioRef.current.value = info.info.price;
                    return;
                }

                var info = await api.get("file-type/" + tipoExamen.value, infoUsuario.token);

                if (!info.error) {
                    //setPrecio(info.info.price);
                    precioRef.current.value = info.info.price;
                    return;
                }
            }
        }
        tr();
    }, [tipoExamen])

    useEffect(() => {
        setModalsVisibility(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
    }, [location]);

    let [forceRender, setForceRender] = useState(0);
    const sendToRender = () => {
        setForceRender(forceRender + 1);
    };

    function agregarExamen() {
        if (tipoExamen == "" || cantidadRef.current.value == 0 || cantidadRef.current.value == "" || precioRef.current.value == 0 || precioRef.current.value == "") {
            dispatch(setError("Debe llenar toda la información sobre el tipo examen"));
            return;
        }
        let nombreTipoExamen = listaTipoExamenes.filter((tipoEx) => { return tipoEx.id == tipoExamen.value })[0].name;
        var ex = { id: tipoExamen, nombre: nombreTipoExamen, cantidad: cantidadRef.current.value, precio: precioRef.current.value };


        setExamenes([...examenes, ex]);
        setTipoExamen("");
        cantidadRef.current.value = 1;
        precioRef.current.value = 0;

    }

    function limpiarExamenes() {
        setExamenes([]);
        setTipoExamen("");
        cantidadRef.current.value = 1;
        precioRef.current.value = 0;
    }

    function eliminarExamen(id) {
        let newExamenes = examenes.filter((ex, index) => { return index != id });
        setExamenes(newExamenes);
    }

    const submitForm = async (event) => {
        event.preventDefault();

        if (paciente == "") {
            dispatch(setError("Debe seleccionar un paciente"));
            return;
        }

        /*if(cliente == ""){
            dispatch(setError("Debe seleccionar un cliente"));
            return;
        }*/
        let doctorId;
        let clientId;
        if (cliente.value) {
            if (cliente.value.includes("c-")) {
                /*if(doctor == ""){
                    dispatch(setError("Debe seleccionar un doctor"));
                    return;
                }*/
                doctorId = doctor.value;
            } else {
                doctorId = null;
            }

            clientId = parseInt(cliente.value.slice(2));
        }


        if (sede == "") {
            dispatch(setError("Debe seleccionar una sede"));
            return;
        }

        if (examenes.length == 0) {
            dispatch(setError("Debe agregar uno o mas procedimientos"));
            return;
        }

        let nuevaOrden = {
            patientId: paciente.value,
            clientId: clientId,
            doctorId: doctorId,
            branchId: sede.value,
            comments: comments,
            detail: examenes.map((ex) => { return { fileTypeId: ex.id.value, unitPrice: parseFloat(ex.precio), quantity: parseInt(ex.cantidad) } })
        }

        var resp = await api.post("order", nuevaOrden, infoUsuario.token);

        if (!resp.error) {
            dispatch(deleteError());

            setPaciente("");
            setCliente("");
            setDoctor("");
            setSede("");
            setComments("");
            setExamenes([]);
            localStorage.removeItem('paciente');
            localStorage.removeItem('sede');

            navigate("/ordenes/" + resp.info.id);
        } else {
            dispatch(setError(resp.error));
        }
    }

    async function traerPacientes(search = '') {
        search = search != '' ? `&search=${search}` : '';
        var info = await api.get('patient?paginate=100&filter={"statusId":1}' + search, infoUsuario.token);

        if (!info.error) {
            setListaPacientes(info.info);

            if (localStorage.getItem("paciente")) {
                setPacienteState(JSON.parse(localStorage.getItem("paciente")))
            }
            return info.info;
        }
    }

    async function traerClinicas() {
        var info = await api.get('clinic?paginate=2000&filter={"statusId":1}', infoUsuario.token);

        if (!info.error) {
            setListaClinicas(info.info);

            if (localStorage.getItem("cliente")) {
                setClienteState(JSON.parse(localStorage.getItem("cliente")))
            }
        }
    }

    async function traerDoctores() {
        var info = await api.get('doctor?paginate=2000&filter={"statusId":1}', infoUsuario.token);

        if (!info.error) {
            setListaDoctores(info.info);

            if (localStorage.getItem("doctor")) {
                setDoctorState(JSON.parse(localStorage.getItem("doctor")))
            }
        }
    }

    async function traerDoctoresPorClinica() {
        let clientId = parseInt(cliente.value.slice(2));
        var info = await api.get("clinic/" + clientId + "/doctor", infoUsuario.token);

        if (!info.error) {
            setListaDoctoresPorClinica(info.info); console.log(optionsDoctoresPorClinica)
        }
    }

    async function traerSedes() {
        var info = await api.get('branch?filter={"statusId":1}', infoUsuario.token);

        if (!info.error) {
            setListaSedes(info.info);

            if (localStorage.getItem("sede")) {
                setSedeState(JSON.parse(localStorage.getItem("sede")))
            }
        }
    }


    async function traerTipoExamenes() {
        var info = await api.get('file-type?paginate=1000&filter={"statusId":1}', infoUsuario.token);

        if (!info.error) {
            setListaTipoExamenes(info.info);
        }
    }

    const setPaciente = selectedOption => {
        setPacienteState(selectedOption);
        if (selectedOption != "") {
            localStorage.setItem("paciente", JSON.stringify(selectedOption));
        }

    };

    const setCliente = selectedOption => {
        setClienteState(selectedOption);
        if (selectedOption != "") {
            localStorage.setItem("cliente", JSON.stringify(selectedOption));
        }

    };

    const setDoctor = selectedOption => {
        setDoctorState(selectedOption);
        if (selectedOption != "") {
            localStorage.setItem("doctor", JSON.stringify(selectedOption));
        }

    };

    const setSede = selectedOption => {
        console.log(selectedOption);
        setSedeState(selectedOption);
        if (selectedOption != "") {
            localStorage.setItem("sede", JSON.stringify(selectedOption));
        }

    };

    const setComments = target => {
        console.log(target);
        setCommentsState(target);
        // if (selectedOption != "") {
        //     localStorage.setItem("comments", JSON.stringify(selectedOption));
        // }

    };

    const cleanForm = () => {
        localStorage.removeItem('paciente');
        localStorage.removeItem('sede');
        window.location.reload();
    }

    let error = '';
    if (infoGeneral.error.length) {
        error = <p className="error">{infoGeneral.error}</p>;
    }
    return (
        <>
            {modals.map((modal, index) => {
                return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender} />
            })}

            <div className="pageHeading">
                <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
                <button onClick={cleanForm}>Limpiar Orden</button>
            </div>
            {error}

            <form onSubmit={submitForm}>
                <div className="row">
                    <h2>Nueva Orden</h2>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Paciente</label>
                        <AsyncSelect
                            styles={
                                {
                                    container: (base) => ({ ...base, width: "100%" })
                                }
                            }
                            value={paciente}
                            onChange={setPaciente}
                            isSearchable={true}
                            required={true}
                            name="paciente"
                            loadOptions={getStorageOptions}
                            defaultOptions={optionsPacientes}
                        />
                        <Link to="newPatient" className="newElement">Agregar Nuevo</Link>
                    </div>

                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Cliente</label>
                        <Select styles={{ container: (base) => ({ ...base, width: "100%" }) }} value={cliente} onChange={setCliente} isSearchable={true} required={false} name="cliente" options={optionsClientes} />
                        <Link to="newClinic" className="newElement">Agregar Nueva Clinica</Link>
                        <Link to="newDoctor" className="newElement">Agregar Nuevo Doctor</Link>
                    </div>
                    <div className="inputGroup" style={showDoctor ? { display: "flex" } : { display: "none" }}>
                        <label>Doctor</label>
                        <Select styles={{ container: (base) => ({ ...base, width: "100%" }) }} value={doctor} onChange={setDoctor} isSearchable={true} required={false} name="doctor" options={optionsDoctoresPorClinica} />

                    </div>

                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Sede</label>
                        <Select styles={{ container: (base) => ({ ...base, width: "100%" }) }} value={sede} onChange={setSede} isSearchable={true} required={true} name="sede" options={optionsSedes} />
                    </div>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Observaciones</label>
                        <textarea name="comments" required={false} rows="3" onChange={e => setComments(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="row">
                    <h2>Examenes</h2>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Tipo Examen</label>
                        <Select styles={{ container: (base) => ({ ...base, width: "100%" }) }} defaultValue={tipoExamen} onChange={setTipoExamen} isSearchable={true} required={true} name="tipoExmane" options={optionsTipoExamen} />

                    </div>
                    <div className="inputGroup">
                        <label>Cantidad</label>
                        <input type="number" name="paciente" defaultValue={1} required={true} ref={cantidadRef} />
                    </div>
                    <div className="inputGroup">
                        <label>Precio</label>
                        <input type="text" name="paciente" defaultValue={0} required={true} ref={precioRef} />
                    </div>
                    <div className="inputGroup">
                        <label>&nbsp;</label>
                        <a className="btn" onClick={agregarExamen}>Agregar</a>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <td>Tipo Examen</td>
                            <td>Cantidad</td>
                            <td>Precio</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {examenes.map((examen, index) => {
                            return <tr key={index}><td>{examen.nombre}</td><td>{examen.cantidad}</td><td>${examen.precio}</td><td><a className="btn delete" onClick={() => { eliminarExamen(index) }}>Eliminar</a></td></tr>
                        })}
                    </tbody>
                </table>

                <div className="row">
                    <button type="submit">Crear</button>
                </div>
            </form>


        </>
    );

};

export default Nuevo;
import React, {useRef, useEffect, useState} from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";

import Api from '../../api.js';
import { useSelector, useDispatch } from 'react-redux'
import { setError, deleteError } from '../../store/infoGeneral.js'
import { setInfoEntidad } from '../../store/infoEntidad.js'

let api = new Api(); 

const EmpleadoLogIn = () => {
    let { newPassWordToken } = useParams();
    let [appName, setAppName] = useState("");
    let password1 = useRef();
    let password2 = useRef();

    
    const infoGeneral = useSelector((state) => state.infoGeneral.value);
    const infoEntidad = useSelector((state) => state.infoEntidad.value);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() =>{
        if(appName === ""){
            return;
        }
        bringEntityInfo();
    },[appName])

    useEffect(()=>{
        let entityName = window.location.hostname.split(".")[0];

        setAppName(entityName);
    })

    const bringEntityInfo = async ()=>{
        var info = await api.get("app/" + appName);

        if(!info.error){
            dispatch(setInfoEntidad(info.info));
        }
        
    }


    const cambiar = async ()=>{
        if(password1.current.value !== password2.current.value){
            dispatch(setError("Las contraseñas no coinciden"));
            return;
        }

        const data = {forgotToken:newPassWordToken, password:password1.current.value, customerId: infoEntidad.id};

        var resp = await api.post("user/reset-password", data, "");

        if(!resp.error){
            dispatch(deleteError());
            dispatch(setError("Listo, puedes intentar loguearte con tu nueva contraseña"))
        }else{
            dispatch(setError(resp.error));
        }
    }

    const checkifPasswordsMatch = ()=>{
        let pass1 = password1.current.value;
        let pass2 = password2.current.value;

        if(pass1.length == 0 || pass2.length == 0){
            dispatch(deleteError());
            return;
        }

        if(pass1 == pass2){
            dispatch(deleteError());
            return;
        }else{
            dispatch(setError("Las contraseñas no coinciden"));
        }
    }

    return (
    <>
        <h2>Recuperar Clave</h2>
        <p className="error">{infoGeneral.error}</p>
        <label>Nueva Contraseña</label>
        <input type="password" onKeyUp={checkifPasswordsMatch} ref={password1}/>
        <label>Repetir Contraseña</label>
        <input type="password" onKeyUp={checkifPasswordsMatch} ref={password2}/>
                
        <button onClick={cambiar}>Cambiar</button>  
        <p className="olvidasteContrasena">Si eres paciente puedes acceder a tus resultados haciendo click <Link to="../misResultados">aqui!</Link>. Eres medico? Haz click <Link to="../">aqui!</Link></p>
    </>
    );
  };
  
  export default EmpleadoLogIn;
import React, {useState, useEffect} from 'react';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Tabla from '../../components/Tabla2.js';

const Modulo = () => {
    //Configurables---------------------------------------------------------------------------
    const titulo = "Sedes";
    const icono = "fa-house-medical";
    const modals = [
        {
            titulo: "Nueva Sede",
            endpoint: "branch",
            buttonText: "Guardar",
            urlCall:"new",
            method:"POST",
            fields: [
                {label:"row", elements:[
                    {label:"Nombre", name:"name", element:"input", type:"text", required:true, isNumber:false}
                ]}
            ],
            dataMask:null
        },
        {
            titulo: "Editar Sede",
            endpoint: "branch",
            buttonText: "Editar",
            urlCall:"edit",
            method:"PUT",
            fields: [
                {label:"row", elements:[
                    {label:"Nombre", name:"name", element:"input", type:"text", required:true, isNumber:false}
                ]}
            ],
            dataMask:null
        }
        ];
    const tabla1 = {
        endpoint: "branch",
        titulos: [{texto:"Sede", name:"name"}, {name:"status"}],
        columnas: [{prop:"name", type:"link", url:"edit/$id$"}, {type:"status", url:"branch"}]
    }

    //Funciones Basicas-----------------------------------------------------------------------
    let { urlCall } = useParams();
    let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal)=>{if(modal.urlCall != urlCall){return "hiddenModal"}else{return "visibleModal"}}));
    
    const navigate = useNavigate();
    let location = useLocation();
    
    const openModal = (modalNumber)=>{
        navigate(modals[modalNumber].urlCall);
    }
    let [forceRender, setForceRender] = useState(0);
    const sendToRender = ()=>{
        setForceRender(forceRender + 1);
    };

    useEffect(()=>{
        let newModalVisibility = modals.map((modal)=>{if(modal.urlCall != urlCall){return "hiddenModal"}else{return "visibleModal"}});
        if(newModalVisibility !== modalsVisibility){setModalsVisibility(newModalVisibility);}
    }, [location]);
    

    return (
    <>
        {modals.map((modal, index) =>{
            return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender}/>
        })}
        
        
        
        <div className="pageHeading">
            <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
            <Link to="new">+</Link>
        </div>

        
        <Tabla info={tabla1} forceRender={forceRender}/>
        
    </>
    );
    
};
  
export default Modulo;
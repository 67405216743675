import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Tabla from '../../components/Tabla2.js';

const Modulo = () => {
    //Configurables---------------------------------------------------------------------------
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    const titulo = "Clientes";
    const icono = "fa-hospital";
    const modals = [
        {
            titulo: "Nuevo Cliente",
            endpoint: "customer",
            buttonText: "Guardar",
            urlCall: "new",
            method: "POST",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Nombre", name: "name", element: "input", type: "text", required: true, isNumber: false },
                        { label: "App Name", name: "appName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Logo", name: "logo", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Icon", name: "icon", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo Cliente", name: "typeId", element: "select", required: true, isNumber: true, options: infoGeneral.customerTypeSelectOptions },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Anexos URL", name: "externalFilesLink", element: "input", type: "text", required: false, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Usuario", element: "subtitle" }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo Documento", name: "documentTypeId", element: "select", required: true, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                        { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: true, isNumber: false },
                        { label: "Sexo", name: "gender", element: "select", required: true, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Teléfono", name: "phone", element: "input", type: "tel", required: true, isNumber: false },
                        { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                    ]
                }
            ],
            dataMask: {
                name: "name",
                appName: "appName",
                logo: "logo",
                icon: "icon",
                typeId: "typeId",
                externalFilesLink: "externalFilesLink",
                "user": {
                    firstName: "firstName",
                    lastName: "lastName",
                    documentTypeId: "documentTypeId",
                    documentNumber: "documentNumber",
                    birthdate: "birthdate",
                    gender: "gender",
                    phone: "phone",
                    email: "email"
                }
            }
        },
        {
            titulo: "Editar Cliente",
            endpoint: "customer",
            buttonText: "Editar",
            urlCall: "edit",
            method: "PUT",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Nombre", name: "name", element: "input", type: "text", required: true, isNumber: false },
                        { label: "App Name", name: "appName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Logo", name: "logo", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Icon", name: "icon", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Anexos URL", name: "externalFilesLink", element: "input", type: "text", required: false, isNumber: false },
                    ]
                },
                // {
                //     label: "row", elements: [
                //         { label: "Tipo Cliente", name: "typeId", element: "select", required: true, isNumber: true, options: infoGeneral.customerTypeSelectOptions },
                //     ]
                // },
                // {
                //     label: "row", elements: [
                //         { label: "Usuario", element: "subtitle" }
                //     ]
                // },
                // {
                //     label: "row", elements: [
                //         { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                //         { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                //     ]
                // },
                // {
                //     label: "row", elements: [
                //         { label: "Tipo Documento", name: "documentTypeId", element: "select", required: true, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                //         { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: true, isNumber: false },
                //     ]
                // },
                // {
                //     label: "row", elements: [
                //         { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: true, isNumber: false },
                //         { label: "Sexo", name: "gender", element: "select", required: true, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                //     ]
                // },
                // {
                //     label: "row", elements: [
                //         { label: "Teléfono", name: "phone", element: "input", type: "tel", required: true, isNumber: false },
                //         { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                //     ]
                // }
            ],
            dataMask: {
                name: "name",
                appName: "appName",
                logo: "logo",
                icon: "icon",
                externalFilesLink: "externalFilesLink",
                typeId: "typeId",
                // "user": {
                //     firstName: "firstName",
                //     lastName: "lastName",
                //     documentTypeId: "documentTypeId",
                //     documentNumber: "documentNumber",
                //     birthdate: "birthdate",
                //     gender: "gender",
                //     phone: "phone",
                //     email: "email"
                // }
            }
        }
    ];
    const tabla1 = {
        endpoint: "customer?paginate=1000",
        titulos: [
            {
                texto: "Nombre", name: "name"
            },
            {
                texto: "Nombre app", name: "appName"
            },
            {
                texto: "Anexos", name: "externalFilesLink"
            },
            {
                texto: "Ordenes", name: "externalFilesLink"
            },
            {
                texto: "Archivos", name: "externalFilesLink"
            },
            {
                texto: "Espacio (GB)", name: "externalFilesLink"
            },
            {
                texto: "Espacio (TB)", name: "externalFilesLink"
            }
        ],
        columnas: [
            {
                prop: "name", type: "link", url: "edit/$id$"
            },
            {
                prop: "appName", type: "link", url: "edit/$id$"
            },
            {
                prop: "externalFilesLink", type: "text", format: (x => {
                    if (x && x != '') {
                        return 'Sí'
                    }
                    return 'No'
                })
            },
            {
                prop: "orders", type: "text"
            },
            {
                prop: "files", type: "text"
            },
            {
                prop: "storage", type: "text", format: (x => {
                    if (x) {
                        return `${parseFloat(x).toFixed(2)}`
                    }
                    return '0'
                })
            },
            {
                prop: "storage", type: "text", format: (x => {
                    if (x) {
                        return `${parseFloat(x / 1024).toFixed(2)}`
                    }
                    return '0'
                })
            }
        ]
    }

    //Funciones Basicas-----------------------------------------------------------------------
    let { urlCall } = useParams();
    let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));

    const navigate = useNavigate();
    let location = useLocation();

    const openModal = (modalNumber) => {
        navigate(modals[modalNumber].urlCall);
    }
    let [forceRender, setForceRender] = useState(0);
    const sendToRender = () => {
        setForceRender(forceRender + 1);
    };

    useEffect(() => {
        let newModalVisibility = modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } });
        if (newModalVisibility !== modalsVisibility) { setModalsVisibility(newModalVisibility); }
    }, [location]);


    return (
        <>
            {modals.map((modal, index) => {
                return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender} />
            })}



            <div className="pageHeading">
                <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
                <Link to="new">+</Link>
            </div>


            <Tabla info={tabla1} noQueries={true} forceRender={forceRender} />

        </>
    );

};

export default Modulo;
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useEffect } from "react";


const Logout = ({ isLoggedIn }) => {
  const infoUsuario = useSelector((state) => state.infoUsuario.value);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      if (infoUsuario.role.name == 'SUPER ADMIN') {
        navigate("/clientes");
      }

      if (infoUsuario.role.name == 'ADMIN') {
        navigate("/ordenes");
      }

      if (infoUsuario.role.name == 'EMPLOYEE') {
        navigate("/ordenes");
      }

      if (infoUsuario.role.name == 'CLINIC') {
        navigate("/pacientes");
      }

      if (infoUsuario.role.name == 'DOCTOR') {
        navigate("/pacientes");
      }

      if (infoUsuario.role.name == 'PATIENT') {
        navigate("/ordenes");
      }
    }
  });

  return (

    <>

    </>
  )
};

export default Logout;
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Form from '../../components/Form.js';
import Tabla from '../../components/Tabla2.js';

const Modulo = () => {
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    let { id, idProcess } = useParams();

    //Configurables---------------------------------------------------------------------------
    const titulo = "Tipo Examen";
    const icono = "fa-clipboard";
    const modals = [
        {
            titulo: "Agregar Proceso",
            endpoint: "file-type/"+id+"/process",
            buttonText: "Guardar",
            urlCall:"newProcess",
            method:"POST",
            fields: [
                {label:"row", elements:[
                    {label:"Nombre Proceso", name:"name", element:"input", type:"text", required:true, isNumber:false},
                    {label:"Paso", name:"step", element:"input", type:"number", required:true, isNumber:true},
                ]},
              ],
            dataMask:null
        },
        {
            titulo: "Editar Proceso",
            endpoint: "file-type/"+id+"/process",
            setId:"idProcess",
            buttonText: "Editar",
            urlCall:"edit",
            method:"PUT",
            fields: [
                {label:"row", elements:[
                    {label:"Nombre Proceso", name:"name", element:"input", type:"text", required:true, isNumber:false},
                    {label:"Paso", name:"step", element:"input", type:"number", required:true, isNumber:true},
                ]},
              ],
            dataMask:null
        }
    ];
    const form = 
    {
        titulo: "Editar Tipo Examen",
        endpoint: "file-type/"+id,
        buttonText: "Editar",
        urlCall:"edit",
        method:"PUT",
        preLoad:true,
        fields: [
            {label:"row", elements:[
                {label:"Información Examen", element:"subtitle"}
            ]},
            {label:"row", elements:[
              {label:"Nombre", name:"name", element:"input", type:"text", required:true, isNumber:false},
              {label:"Precio", name:"price", element:"input", type:"number", required:true, isNumber:true}
            ]}
          ],
        dataMask:null
    };
    const tabla1 = {
        endpoint: "file-type/"+id+"/process",
        titulos: [{texto:"Nombre", name:"name"}, {texto:"Paso", name:"step"}, {name:"status"}],
        columnas: [{prop:"name", type:"link", url:"edit/$id$"}, {prop:"step", type:"text"}, {type:"status", url:"file-type/"+id+"/process"}]
    }

    //Funciones Basicas-----------------------------------------------------------------------
    let { urlCall } = useParams();
    let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal)=>{if(modal.urlCall != urlCall){return "hiddenModal"}else{return "visibleModal"}}));
    const navigate = useNavigate();
    let location = useLocation();

    let [forceRender, setForceRender] = useState(0);
    const sendToRender = ()=>{
        setForceRender(forceRender + 1);
    };


    useEffect(()=>{
        setModalsVisibility(modals.map((modal)=>{if(modal.urlCall != urlCall){return "hiddenModal"}else{return "visibleModal"}}));
    }, [location]);
    

    return (
    <>
        {modals.map((modal, index) =>{
            return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender}/>
        })}
        <div className="pageHeading">
            <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
        </div>
        
        <Form info={form}/>

        <div className="pageHeading">
            <h1><FontAwesomeIcon icon="fa-user-doctor" /> Procesos</h1>
            <Link to="newProcess">+</Link>
        </div>

        <Tabla info={tabla1} noQueries={true} forceRender={forceRender}/>
    </>
    );
    
};
  
export default Modulo;
import React, {useRef, useEffect, useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import Api from '../../api.js';
import { useSelector, useDispatch } from 'react-redux'
import { setError, deleteError } from '../../store/infoGeneral.js'
import { setInfoEntidad } from '../../store/infoEntidad.js'


let api = new Api(); 

const EmpleadoLogIn = () => {
    //let { appName } = useParams();
    let [appName, setAppName] = useState("");
    let email = useRef();

    
    const infoGeneral = useSelector((state) => state.infoGeneral.value);
    const infoEntidad = useSelector((state) => state.infoEntidad.value);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(()=>{
        let entityName = window.location.hostname.split(".")[0];

        setAppName(entityName);
    })

    useEffect(() =>{
        if(appName === ""){
            return;
        }
        bringEntityInfo();
    },[appName])

    const bringEntityInfo = async ()=>{
        var info = await api.get("app/" + appName);

        if(!info.error){
            dispatch(setInfoEntidad(info.info));
        }
        
    }


    const recuperar = async ()=>{
        const data = {email:email.current.value, customerId: infoEntidad.id};

        var resp = await api.post("user/forgot-password", data, "");

        if(!resp.error){
            dispatch(deleteError());
            dispatch(setError("Hemos enviado un correo con las instrucciones a este email."))
        }else{
            dispatch(setError(resp.error));
        }
    }

    return (
    <>
        <h2>Recuperar Clave</h2>
        <p className="error" style={{color: "green"}}>{infoGeneral.error}</p>
        <label>Email</label>
        <input type="email" ref={email} />
                
        <button onClick={recuperar}>Recuperar</button>  
        <p className="olvidasteContrasena">Tienes tu clave? Vuelve al  <Link onClick={() => navigate(-1)}>login!</Link></p>
    </>
    );
  };
  
  export default EmpleadoLogIn;
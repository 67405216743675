import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Tabla from '../../components/Tabla2.js';

const Modulo = () => {
  let infoGeneral = useSelector((state) => state.infoGeneral.value);

  //Configurables---------------------------------------------------------------------------
  const titulo = "Empleados";
  const icono = "fa-user-nurse";
  const modals = [
    {
      titulo: "Nuevo Empleado",
      endpoint: "employee",
      buttonText: "Guardar",
      urlCall: "new",
      method: "POST",
      fields: [
        {
          label: "row", elements: [
            { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
            { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
          ]
        },
        {
          label: "row", elements: [
            { label: "Tipo Documento", name: "documentTypeId", element: "select", required: false, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
            { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: false, isNumber: false },
          ]
        },
        {
          label: "row", elements: [
            { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
            { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
          ]
        },
        {
          label: "row", elements: [
            { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
            { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
          ]
        },
        {
          label: "row", elements: [
            { label: "Puede Firmar", name: "can_sign", element: "input", type: "checkbox", required: false, isNumber: false },
          ]
        },
        {
          label: "row", elements: [
            { label: "Firma (URL)", name: "signature", element: "input", type: "text", required: false, isNumber: false },
          ]
        }
      ],
      dataMask: null
    },
    {
      titulo: "Editar Empleado",
      endpoint: "employee",
      buttonText: "Editar",
      urlCall: "edit",
      method: "PUT",
      fields: [
        {
          label: "row", elements: [
            { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
            { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
          ]
        },
        {
          label: "row", elements: [
            { label: "Tipo Documento", name: "documentTypeId", element: "select", required: false, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
            { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: false, isNumber: false },
          ]
        },
        {
          label: "row", elements: [
            { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
            { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
          ]
        },
        {
          label: "row", elements: [
            { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
            { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
          ]
        },
        {
          label: "row", elements: [
            { label: "Puede Firmar", name: "can_sign", element: "input", type: "checkbox", required: false, isNumber: false, value: true },
          ]
        },
        {
          label: "row", elements: [
            { label: "Firma (URL)", name: "signature", element: "input", type: "text", required: false, isNumber: false },
          ]
        }
      ],
      dataMask: null
    }
  ];

  const tipoDocumento = (numb) => {
  }
  const tabla1 = {
    endpoint: "employee",
    titulos: [
      {
        texto: "Nombres", name: "firstName"
      },
      {
        texto: "Apellidos", name: "lastName"
      },
      {
        texto: "Email", name: "email"
      },
      {
        texto: "Tipo Documento", name: "documentTypeId"
      },
      {
        texto: "Documento", name: "documentNumber"
      },
      {
        texto: "Puede Firmar", name: "can_sign"
      },
      {
        name: "status"
      }],
    columnas: [
      {
        prop: "firstName", type: "link", url: "edit/$id$"
      },
      {
        prop: "lastName", type: "link", url: "edit/$id$"
      },
      {
        prop: "email", type: "text"
      },
      {
        prop: "documentTypeId", type: "text", format: (x) => {
          let selectTxt = infoGeneral.documentTypeSelectOptions.filter((d) => {
            return d.value == x
          });
          if (selectTxt.length > 0) {
            return selectTxt[0].text
          }
        }
      },
      {
        prop: "documentNumber", type: "text"
      },
      {
        prop: "can_sign", type: "text", format: (x) => {
          return x ? 'Sí' : 'No';
        }
      },
      {
        type: "status", url: "employee"
      }]
  }

  //Funciones Basicas-----------------------------------------------------------------------
  let { urlCall } = useParams();
  let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
  const navigate = useNavigate();
  let location = useLocation();

  const openModal = (modalNumber) => {
    navigate(modals[modalNumber].urlCall);
  }
  let [forceRender, setForceRender] = useState(0);
  const sendToRender = () => {
    setForceRender(forceRender + 1);
  };

  useEffect(() => {
    setModalsVisibility(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
  }, [location]);


  return (
    <>
      {modals.map((modal, index) => {
        return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender} />
      })}



      <div className="pageHeading">
        <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
        <Link to="new">+</Link>
      </div>



      <Tabla info={tabla1} forceRender={forceRender} />

    </>
  );

};

export default Modulo;
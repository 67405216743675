import { Outlet, Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHouseMedical, faBuilding, faUserNurse, faHospital, faUserDoctor, faGear, faLock, faRightFromBracket, faClipboard, faBed, faFile, faArrowLeft, faQuestion, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux'


const DashboardLayout = ({ isLoggedIn }) => {
  const infoEntidad = useSelector((state) => state.infoEntidad.value);
  const infoUsuario = useSelector((state) => state.infoUsuario.value);
  const navigate = useNavigate();
  let location = useLocation();
  library.add(faHouseMedical);
  library.add(faBuilding);
  library.add(faUserNurse);
  library.add(faHospital);
  library.add(faUserDoctor);
  library.add(faClipboard);
  library.add(faBed);
  library.add(faFile);
  library.add(faGear);
  library.add(faLock);
  library.add(faRightFromBracket);
  library.add(faArrowLeft);
  library.add(faQuestion);
  library.add(faQuestionCircle);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  });

  let menu = [];
  let menuConfiguracion = [];
  let menuPersonal = [];

  if (infoUsuario.role.name === 'SUPER ADMIN') {
    menuPersonal.push({ url: '/miCuenta', icon: "fa-gear", name: "Mi Cuenta" });

    menuConfiguracion.push({ url: '/clientes', icon: "house-medical", name: "Clientes", pages: ["clientes", "sede"] });
  }

  if (infoUsuario.role.name === 'ADMIN') {
    menuPersonal.push({ url: '/miCuenta', icon: "fa-gear", name: "Mi Cuenta" });

    menu.push({ url: '/ordenes', icon: "fa-file", name: "Ordenes", pages: ["ordenes", "orden"] });
    menuConfiguracion.push({ url: '/sedes', icon: "house-medical", name: "Sedes", pages: ["sedes", "sede"] });
    menuConfiguracion.push({ url: '/empleados', icon: "user-nurse", name: "Empleados", pages: ["empleados", "empleado"] });
    menuConfiguracion.push({ url: '/tipoExamenes', icon: "fa-clipboard", name: "Tipos de Examenes", pages: ["tipoExamenes", "tipoExamen"] });
    menu.push({ url: '/clinicas', icon: "fa-hospital", name: "Clinicas", pages: ["clinicas", "clinica"] });
    menu.push({ url: '/doctores', icon: "fa-user-doctor", name: "Doctores", pages: ["doctores", "doctor"] });
    menu.push({ url: '/pacientes', icon: "fa-bed", name: "Pacientes", pages: ["pacientes", "paciente"] });

  }

  if (infoUsuario.role.name === 'EMPLOYEE') {
    menuPersonal.push({ url: '/miCuenta', icon: "fa-gear", name: "Mi Cuenta" });

    menu.push({ url: '/ordenes', icon: "fa-file", name: "Ordenes", pages: ["ordenes", "orden"] });
    //menuConfiguracion.push({url:'/sedes', icon:"house-medical", name:"Sedes", pages:["sedes"]});
    //menuConfiguracion.push({url:'/empleados', icon:"user-nurse", name:"Empleados", pages:["empleados", "empleado"]});
    //menuConfiguracion.push({url:'/tipoExamenes', icon:"fa-clipboard", name:"Tipos de Examenes", pages:["tipoExamenes", "tipoExamen"]});
    menu.push({ url: '/clinicas', icon: "fa-hospital", name: "Clinicas", pages: ["clinicas", "clinica"] });
    menu.push({ url: '/doctores', icon: "fa-user-doctor", name: "Doctores", pages: ["doctores", "doctor"] });
    menu.push({ url: '/pacientes', icon: "fa-bed", name: "Pacientes", pages: ["pacientes", "paciente"] });
  }

  if (infoUsuario.role.name === 'CLINIC') {
    menuPersonal.push({ url: '/miCuenta', icon: "fa-gear", name: "Mi Cuenta" });

    menu.push({ url: '/doctores', icon: "fa-user-doctor", name: "Doctores", pages: ["doctores", "doctor"] });
    menu.push({ url: '/pacientes', icon: "fa-bed", name: "Pacientes", pages: ["pacientes", "paciente"] });
  }

  if (infoUsuario.role.name === 'DOCTOR') {
    menuPersonal.push({ url: '/miCuenta', icon: "fa-gear", name: "Mi Cuenta" });

    menu.push({ url: '/pacientes', icon: "fa-bed", name: "Pacientes", pages: ["pacientes", "paciente"] });
    menu.push({ url: 'https://web.clin2cloud.com/preguntas-frecuentes/', icon: "fa-question", name: "Preguntas Frecuentes", pages: [] });
  }

  if (infoUsuario.role.name == 'PATIENT') {

    menu.push({ url: '/ordenes', icon: "fa-file", name: "Ordenes", pages: ["ordenes", "orden"] });
  }

  menuPersonal.push({ url: '/logout', icon: "fa-right-from-bracket", name: "Cerrar Sesión" });

  return (

    <>
      <header>
        <div id="back-button">
          <a href="javascript:window.history.back();">
            <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
          </a>
        </div>
        <div id="logo">
          <img src={infoEntidad.logo} />
        </div>

        <div id="headerBtns">
          <Link to="/miCuenta"><FontAwesomeIcon icon="fa-gear" /></Link>
          {/* <Link to="googl"><FontAwesomeIcon icon="fa-lock" /></Link> */}
          <Link to="/logout"><FontAwesomeIcon icon="fa-right-from-bracket" /></Link>
        </div>
      </header>

      <nav id="mainMenu">
        {infoUsuario.role.name == "EMPLOYEE" || infoUsuario.role.name == "ADMIN" ? <Link id="nuevaOrdenBtn" to="/ordenes/nueva">Nueva Orden</Link> : <></>}

        <h2 style={menu.length == 0 ? { display: "none" } : {}}>Menu Principal</h2>
        {menu.map((element, index) => {
          let className = '';
          if (element.pages.includes(location.pathname.split("/")[1])) {
            className = 'selected';
          } else {
            className = '';
          }
          if (element.url.startsWith('https://')) {
            return <a href={element.url} target="_blank"><FontAwesomeIcon icon={element.icon} /><span>{element.name}</span></a>
          } else {
            return <Link key={index} className={className} to={element.url}><FontAwesomeIcon icon={element.icon} /> <span>{element.name}</span></Link>
          }
        })}

        <h2 style={menuConfiguracion.length == 0 ? { display: "none" } : {}}>Configuración</h2>
        {menuConfiguracion.map((element, index) => {
          let className = '';
          if (element.pages.includes(location.pathname.split("/")[1])) {
            className = 'selected';
          } else {
            className = '';
          }
          return <Link key={index} className={className} to={element.url}><FontAwesomeIcon icon={element.icon} /> <span>{element.name}</span></Link>
        })}

        <h2>Mi Cuenta</h2>
        <div id="personalLeftMenu">
          {menuPersonal.map((element, index) => {
            let className = '';
            if (location.pathname == element.url) {
              className = 'selected';
            } else {
              className = '';
            }
            return <Link key={index} className={className} to={element.url}><FontAwesomeIcon icon={element.icon} /> {element.name}</Link>
          })}
        </div>
      </nav>
      <Outlet />
    </>
  )
};

export default DashboardLayout;
import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'infoEntidad',
  initialState: {
    value: {
      appName: "Clic2Cloud",
      icon: "https://clin2cloud.com/wp-content/uploads/2023/11/icono-web.png",
      id: "",
      logo: "https://clin2cloud.com/wp-content/uploads/2023/11/LOGO-Clin2Cloud-blanco.png",
      name: "Clin2Cloud",
      typeId: "",
    },
  },
  reducers: {
    setInfoEntidad: (state, info) => {
      state.value.appName = info.payload.appName;
      state.value.icon = info.payload.icon;
      state.value.id = info.payload.id;
      state.value.logo = info.payload.logo;
      state.value.name = info.payload.name;
      state.value.typeId = info.payload.typeId;
      state.value.externalFilesLink = info.payload.externalFilesLink;
    },
    deleteInfoEntidad: (state) => {
      state.value.appName = "";
      state.value.icon = "";
      state.value.id = "";
      state.value.logo = "";
      state.value.name = "";
      state.value.typeId = "";
      state.value.externalFilesLink = "";
    }
  }
})

// Action creators are generated for each case reducer function
export const { setInfoEntidad, deleteInfoEntidad } = counterSlice.actions

export default counterSlice.reducer
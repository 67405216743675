import React, { useRef, useState, useEffect } from 'react';
import Api from '../../api.js';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useParams } from "react-router-dom";
import SelectSearch from 'react-select-search';
import Select from 'react-select';

import { setError, deleteError } from '../../store/infoGeneral.js'

const Nuevo = () => {

    let api = new Api();

    const titulo = "Orden";
    const icono = "fa-file";

    const dispatch = useDispatch();
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    let infoUsuario = useSelector((state) => state.infoUsuario.value);
    let location = useLocation();
    let [listaPacientes, setListaPacientes] = useState([]);
    let [listaClinicas, setListaClinicas] = useState([]);
    let [listaDoctores, setListaDoctores] = useState([]);
    let [listaSedes, setListaSedes] = useState([]);
    let [listaTipoExamenes, setListaTipoExamenes] = useState([]);
    let [paciente, setPaciente] = useState("");
    let [cliente, setCliente] = useState("");
    let [doctor, setDoctor] = useState("");
    let [estado, setEstado] = useState("");
    let [showDoctor, setShowDoctor] = useState(false);
    let [sede, setSede] = useState("");
    let [comments, setComments] = useState("");
    let [examenes, setExamenes] = useState([]);
    let [cantidad, setCantidad] = useState(1);
    let [precio, setPrecio] = useState(0);
    let [tipoExamen, setTipoExamen] = useState("");
    let [clientId, setClientId] = useState(0);

    let { id } = useParams();

    //Referencias
    let obj = useRef([]);
    let precioRef = useRef();
    let cantidadRef = useRef();






    const optionsDoctores = [
        {
            type: 'group',
            name: 'Doctores',
            items: listaDoctores.map((x) => { return { name: x.firstName + " " + x.lastName, value: "d-" + x.id } })
        },
    ];

    const optionsTipoExamen = listaTipoExamenes.map((x) => { return { label: x.name, value: x.id } });



    useEffect(() => {
        if (infoUsuario.token !== null) {
            traerDoctores();
            /*traerTipoExamenes();
            traerClinicas();
            traerDoctores();
            traerSedes();*/traerTipoExamenes(); traerOrden();
        }
    }, [location, infoUsuario]);

    useEffect(() => {
        async function tr() {
            if (tipoExamen == "") {
                setTipoExamen("");
                return;
            }
            /*if (cliente == "") {
                dispatch(setError("Debe seleccionar un cliente primero"));
                setTipoExamen("");
                return;
            }*/

            dispatch(deleteError());
            if (cliente !== "") {
                let clientId = cliente.value.slice(2);
                var info = await api.get("file-type/" + tipoExamen.value + "/client-price/" + clientId, infoUsuario.token);
            } else {
                var info = await api.get("file-type/" + tipoExamen.value, infoUsuario.token);
            }


            if (!info.error) {
                setCantidad(1);
                if (info.info) {
                    precioRef.current.value = info.info.price;
                    return;
                }

                var info = await api.get("file-type/" + tipoExamen.value, infoUsuario.token);

                if (!info.error) {
                    precioRef.current.value = info.info.price;
                    return;
                }
            }
        }
        tr();
    }, [tipoExamen])


    function eliminarExamen(id) {
        /*let newExamenes = examenes.filter((ex, index) =>{return index != id});
        setExamenes(newExamenes);*/
    }

    const submitForm = async (event) => {
        /*event.preventDefault();

       
        if(sede == ""){
            dispatch(setError("Debe seleccionar una sede"));
            return;
        }

        if(examenes.length == 0){
            dispatch(setError("Debe agregar uno o mas procedimientos"));
            return;
        }

        let nuevaOrden = {
            
            branchId:sede,
            detail:examenes.map((ex)=>{return {fileTypeId:ex.id, unitPrice:ex.precio, quantity:ex.cantidad}})

        }

        var resp = await api.post("order", nuevaOrden, infoUsuario.token);

        if(!resp.error){
            alert("Creado");
            dispatch(deleteError());
            setSede("");
            setExamenes([]);
        }else{
            dispatch(setError(resp.error));
        }*/
    }

    async function traerOrden() {
        var info = await api.get("order/" + id, infoUsuario.token);

        if (!info.error) {
            if (info.info.branch != null) {
                setSede(info.info.branch.name);
            } else {
                setSede("No hay sede seleccionada");
            }
            const estados = ["Activa", "Inactiva", "En Pausa", "Completada", "Eliminada"]
            setEstado(estados[info.info.statusId - 1]);
            setClientId(info.info.clientId);
            setPaciente(info.info.patient.firstName + " " + info.info.patient.lastName);

            if (info.info.client) {
                if (info.info.client.hasOwnProperty("tradename")) {
                    setCliente(info.info.client.tradename);
                    setDoctor("d-" + info.info.doctorId);
                    setShowDoctor(true);
                } else {
                    setCliente(`${info.info.client.firstName} ${info.info.client.lastName}`);
                    setShowDoctor(false);
                }
            }

            setComments(info.info.comments);

            setExamenes(info.info.orderDetails.map((ex) => {
                return { id: ex.id, nombre: ex.fileType.name, cantidad: ex.quantity, precio: ex.unitPrice }
            }))

        }
    }

    async function traerDoctores() {
        var info = await api.get('doctor?paginate=2000&filter={"statusId":1}', infoUsuario.token);

        if (!info.error) {
            setListaDoctores(info.info);
        }
    }



    async function traerTipoExamenes() {
        var info = await api.get('file-type?filter={"statusId":1}', infoUsuario.token);

        if (!info.error) {
            setListaTipoExamenes(info.info);
            traerOrden();
        }
    }

    async function agregarExamen() {
        if (tipoExamen == "" || cantidadRef.current.value == 0 || cantidadRef.current.value == "" || precioRef.current.value == 0 || precioRef.current.value == "") {
            dispatch(setError("Debe llenar toda la información sobre el tipo examen"));
            return;
        }

        let nombreTipoExamen = listaTipoExamenes.filter((tipoEx) => { return tipoEx.id == tipoExamen.value })[0].name;
        var ex = { fileTypeId: tipoExamen.value, quantity: parseInt(cantidadRef.current.value), unitPrice: parseFloat(precioRef.current.value) };

        var resp = await api.post("order/" + id + "/detail", ex, infoUsuario.token);

        if (!resp.error) {
            dispatch(deleteError());

            setTipoExamen("");
            cantidadRef.current.value = 1;
            precioRef.current.value = 0;
            traerOrden();
        } else {
            dispatch(setError(resp.error));
        }
    }

    async function eliminarExamen(idExamen) {
        var info = await api.delete("order/" + id + "/detail/" + idExamen, infoUsuario.token);

        if (!info.error) {
            traerOrden();
        }
    }

    const completado = async () => {
        var resp = await api.put("order/" + id + "/complete", [], infoUsuario.token);

        if (!resp.error) {
            dispatch(deleteError());
            alert("Terminado");
        } else {
            dispatch(setError(resp.error));
        }
    }

    let error = '';
    if (infoGeneral.error.length) {
        error = <p className="error">{infoGeneral.error}</p>;
    }
    return (
        <>
            <div className="pageHeading">
                <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
            </div>
            {error}

            <form onSubmit={submitForm} style={{ marginBottom: "200px" }}>
                <div className="row">
                    <h2>Orden <span style={{ fontSize: "12px", fontWeight: "bold", backgroundColor: "var(--accentColor)", color: "#FFF", padding: "5px", borderRadius: "5px", position: "relative", top: "-5px" }}>{estado}</span></h2>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Paciente</label>
                        <input type="text" name="paciente" value={paciente} disabled={true} />
                    </div>

                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Cliente</label>
                        <input type="text" name="cliente" value={cliente} disabled={true} />
                    </div>
                    <div className="inputGroup" style={showDoctor ? { display: "block" } : { display: "none" }}>
                        <label>Doctor</label>
                        <SelectSearch options={optionsDoctores} name="doctor" value={doctor} placeholder="Seleccione un Doctor" search={true} onChange={setDoctor} disabled={true} />
                    </div>

                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Sede</label>
                        <input type="text" name="sede" value={sede} disabled={true} />
                    </div>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Observaciones</label>
                        <textarea name="comments" required={false} rows="3" disabled={true} value={comments}></textarea>
                    </div>
                </div>
                {/* <div className="row" style={infoUsuario.role.name == "PATIENT" || estado == "Completada" ? { display: "none" } : { display: "block" }}>
                    <h2>Examenes</h2>
                </div>
                <div className="row" style={infoUsuario.role.name == "PATIENT" || estado == "Completada" ? { display: "none" } : { display: "block" }}>
                    <div className="inputGroup">
                        <label>Tipo Examen</label>
                        <Select styles={{ container: (base) => ({ ...base, width: "100%" }) }} defaultValue={tipoExamen} onChange={setTipoExamen} isSearchable={true} required={true} name="tipoExmane" options={optionsTipoExamen} />
                    </div>
                    <div className="inputGroup">
                        <label>Cantidad</label>
                        <input type="number" name="paciente" defaultValue={1} required={true} ref={cantidadRef} />
                    </div>
                    <div className="inputGroup">
                        <label>Precio</label>
                        <input type="text" name="paciente" defaultValue={0} required={true} ref={precioRef} />
                    </div>
                    <div className="inputGroup">
                        <label>&nbsp;</label>
                        <a className="btn" onClick={agregarExamen}>Agregar</a>
                    </div>
                </div> */}

                <div className="row" style={infoUsuario.role.name == "PATIENT" || estado == "Completada" ? { display: "none" } : { display: "block" }}>
                    <button type="button" style={{ marginLeft: "20px" }} onClick={completado}>Marcar como Terminado</button>
                </div>

                <table>
                    <thead>
                        {
                            (infoUsuario.role.name === "PATIENT" || infoUsuario.role.name === "CLINIC" || infoUsuario.role.name === "DOCTOR") ?
                                (<tr><td>Tipo Examen</td>
                                    <td>Cantidad</td>
                                    <td></td></tr>)
                                :
                                (<tr><td>Tipo Examen</td>
                                    <td>Cantidad</td>
                                    <td>Precio</td>
                                    <td></td></tr>
                                )
                        }
                    </thead>
                    <tbody>
                        {
                            (infoUsuario.role.name === "PATIENT" || infoUsuario.role.name === "CLINIC" || infoUsuario.role.name === "DOCTOR") ?
                                (
                                    examenes.map((examen, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><Link to={"examen/" + examen.id}>{examen.nombre}</Link></td>
                                                <td>{examen.cantidad}</td>
                                                <td></td>
                                            </tr>);

                                    })
                                )
                                :
                                (
                                    (
                                        examenes.map((examen, index) => {
                                            let eliminarBtn;
                                            if (estado !== "Completada") {
                                                eliminarBtn = <a className="btn delete" onClick={() => { eliminarExamen(examen.id) }}>Eliminar</a>;
                                            } else {
                                                eliminarBtn = '';
                                            }

                                            return (
                                                <tr key={index}>
                                                    <td><Link to={"examen/" + examen.id}>{examen.nombre}</Link></td>
                                                    <td>{examen.cantidad}</td>
                                                    <td>${examen.precio}</td>
                                                    <td>{eliminarBtn}</td>
                                                </tr>);

                                        })
                                    )
                                )
                        }
                    </tbody>
                </table>

                {<div className="row">
                    {estado == "Activa" || estado == "En Pausa" ? <a href={"editar/" + id} className="btn" style={{ textDecoration: "none" }}>Editar Orden</a> : ""}
                </div>}
            </form>


        </>
    );

};

export default Nuevo;
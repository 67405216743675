import { configureStore } from '@reduxjs/toolkit'
import infoGeneralReducer from './infoGeneral'
import infoUsuarioReducer from './infoUsuario'
import infoEntidadReducer from './infoEntidad'

export default configureStore({
  reducer: {
    infoGeneral: infoGeneralReducer,
    infoUsuario: infoUsuarioReducer,
    infoEntidad: infoEntidadReducer
  },
})
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Api from '../api.js';
import Select from 'react-select';

const Modal = ({ modalNumber, visibility, info, sendToRender }) => {
    let api = new Api();
    let infoUsuario = useSelector((state) => state.infoUsuario.value);
    let obj = useRef([]);
    const navigate = useNavigate();
    let [alert, setAlert] = useState({ text: "", style: "alertHidden" });
    let { id } = useParams();
    let params = useParams();
    let location = useLocation();
    //1
    let oh = {};
    info.fields.forEach((row) => {
        row.elements.forEach((element) => {
            if (element.element == "select") {
                oh[element.name] = null;
            }
        })
    })
    let [selects, setSelects] = useState(oh);

    const cleanForm = () => {
        for (const key in obj.current) {
            obj.current[key].value = "";
        }

        let newSelects = {};
        for (const key in selects) {
            newSelects[key] = [];
        }
        setSelects(newSelects);

    }

    const closeModal = () => {
        setAlert({ text: "", style: "alertHidden" });
        cleanForm();
        //navigate("../");
        //navigate(location.pathname.split("/").pop().join(""))
        let newUrl = location.pathname.split(info.urlCall);
        //newUrl.pop();
        navigate(newUrl[0]);
    }

    const handleForm = async (event) => {
        event.preventDefault();

        let data = {};
        let resp;
        info.fields.forEach((row) => {
            row.elements.map((element) => {
                if (element.element == "input") {
                    if (element.isNumber) {
                        data[element.name] = parseInt(obj.current[element.name].value);
                    } else {
                        if (element.type == "email") {
                            data[element.name] = obj.current[element.name].value.toLowerCase();
                        } else if (element.type == "checkbox") {
                            console.log(obj.current[element.name], obj.current[element.name].checked);
                            data[element.name] = obj.current[element.name].checked
                        } else {
                            data[element.name] = obj.current[element.name].value;
                        }
                    }

                }

                if (element.element == "select") {
                    if (element.multiple) {
                        if (element.isNumber) {
                            if (selects[element.name] == undefined) {
                                data[element.name] = [];
                            } else {
                                data[element.name] = selects[element.name].map((val) => { return parseInt(val.value) });
                            }

                        } else {
                            if (selects[element.name] == undefined) {
                                data[element.name] = [];
                            } else {
                                data[element.name] = selects[element.name].map((val) => { return val.value });
                            }
                        }
                    } else {
                        if (element.isNumber) {
                            if (selects[element.name] == undefined) {
                                data[element.name] = null;
                            } else {
                                data[element.name] = selects[element.name].value;
                            }
                        } else {
                            if (selects[element.name] == undefined) {
                                data[element.name] = null;
                            } else {
                                data[element.name] = selects[element.name].value;
                            }
                        }
                    }


                }
            })
        })

        if (info.dataMask != null) {
            data = convertObject(data, info.dataMask);
        }

        if (info.method == "POST") {
            resp = await api.post(info.endpoint, data, infoUsuario.token);
        } else if (info.method == "PUT") {
            if (info.setId) {
                resp = await api.put(info.endpoint + "/" + params[info.setId], data, infoUsuario.token);
            } else {
                resp = await api.put(info.endpoint + "/" + id, data, infoUsuario.token);
            }

        } else {
            resp = await api.get(info.endpoint + data[info.variableName], infoUsuario.token);
        }

        if (info.method == "POST" || info.method == "PUT") {
            if (!resp.error) {
                setAlert({ text: "Guardado", style: "alertSuccess" });
                if (info.method == "POST") {
                    cleanForm();
                }
                sendToRender();

                if (info.afterSubmitting) {
                    info.afterSubmitting(resp);
                }

            } else {
                setAlert({ text: resp.error, style: "alertError" });
            }
        } else {
            info.callBack(resp);
        }

    }

    const convertObjectOriginal = (obje, newStructure) => {
        // create a new empty object to store the converted data
        const convertedObj = {};

        // loop through the keys in the new structure
        for (const key of Object.keys(newStructure)) {
            // get the value for the current key in the new structure
            const value = newStructure[key];

            // check if the value is a string
            if (typeof value === 'string') {
                // if the value is a string, get the corresponding value from the original object
                convertedObj[key] = obje[value];
            } else if (Array.isArray(value)) {
                // if the value is an array, it should contain a list of keys to extract from the original object
                convertedObj[key] = value.map(v => obje[v]);
            } else {
                // if the value is an object, it represents a nested structure to convert
                convertedObj[key] = convertObject(obje, value);
            }
        }

        return convertedObj;
    }

    const convertObject = (obje, newStructure) => {
        // create a new empty object to store the converted data
        const convertedObj = {};

        // loop through the keys in the new structure
        for (const key of Object.keys(newStructure)) {
            // get the value for the current key in the new structure
            const value = newStructure[key];

            // check if the value is a string
            if (typeof value === 'string') {
                // if the value is a string, get the corresponding value from the original object
                convertedObj[key] = obje[value];
            } else if (Array.isArray(value)) {
                // if the value is an array, it should contain a list of keys to extract from the original object
                convertedObj[key] = [];
                value.forEach(v => {
                    if (typeof v === 'string') {
                        convertedObj[key].push(obje[v]);
                    } else {
                        let found = false;
                        convertedObj[key].forEach(element => {
                            if (Object.keys(element).every(k => element[k] === obje[k])) {
                                found = true;
                                return;
                            }
                        });
                        if (!found) {
                            convertedObj[key].push(convertObject(obje, v));
                        }
                    }
                });
            } else {
                // if the value is an object, it represents a nested structure to convert
                convertedObj[key] = convertObject(obje, value);
            }
        }

        return convertedObj;
    };



    useEffect(() => {
        cleanForm();
        if (infoUsuario.token !== null && id && info.urlCall == "edit") {
            traer();
        }
        preloadValues();
    }, [location]);

    const preloadValues = () => {
        let newSelects = {};
        info.fields.forEach((row) => {
            row.elements.map((element) => {
                if (element.hasOwnProperty("value")) {
                    if (element.element == "input") {
                        obj.current[element.name].value = element.value
                    }
                    if (element.element == "select") {

                        let ids = element.options.filter((opt) => { return opt.value == element.value }).map((s) => { return { value: s.value, label: s.text } });
                        if (element.multiple) {
                            newSelects[element.name] = ids;
                        } else {
                            newSelects[element.name] = ids[0];
                        }
                    }

                }
            })
        })
        setSelects(newSelects);
    }

    async function traer() {
        var url = info.endpoint + "/" + id;

        if (info.setId) {
            url = info.endpoint + "/" + params[info.setId];
        }
        var resp = await api.get(url, infoUsuario.token);
        //3
        let newSelects = {};
        if (!resp.error) {
            info.fields.forEach((row) => {
                row.elements.map((element, index) => {
                    if (element.element == "input") {
                        obj.current[element.name].value = resp.info[element.name];
                        if (element.type == "checkbox") {
                            obj.current[element.name].checked = resp.info[element.name]
                        }
                    }
                    if (element.element == "select") {

                        let ids = element.options.filter((opt) => { return opt.value == resp.info[element.name] }).map((s) => { return { value: s.value, label: s.text } });
                        if (element.multiple) {
                            newSelects[element.name] = ids;
                        } else {
                            newSelects[element.name] = ids[0];
                        }
                    }
                })

            });

            //5
            setSelects(newSelects);
        }
    }

    async function handleChange(name, val) {
        setSelects({ ...selects, [name]: val });
    }

    return (
        <>
            <div id="curtain" className={visibility[modalNumber]}>
                <div id="newModal" className={"modal " + visibility[modalNumber]}>
                    <div className="modalHeader">
                        <h2>{info.titulo}</h2>
                        <button className="closeModalBtn" onClick={closeModal}>x</button>
                    </div>
                    <form onSubmit={handleForm}>
                        <div className="modalBody">
                            <p className={"alert " + alert.style}>{alert.text}</p>

                            {info.fields.map((row, indexRow) => {
                                return <div key={indexRow} className="row">
                                    {row.elements.map((element, index) => {
                                        if (element.element == "input") {
                                            return (
                                                <div key={index} className="inputGroup" style={element.hasOwnProperty("hidden") ? { display: "none" } : { display: "flex" }}>
                                                    <label>{element.label}</label>
                                                    <input key={index} type={element.type} name={element.name} ref={(elementRef) => obj.current[element.name] = elementRef} required={element.required} />
                                                </div>)
                                        }

                                        //2
                                        if (element.element == "select") {

                                            return (
                                                <div key={index} className="inputGroup" style={element.hasOwnProperty("hidden") ? { display: "none" } : { display: "flex" }}>
                                                    <label>{element.label}</label>
                                                    <Select key={index} value={selects[element.name]} styles={{ container: (base) => ({ ...base, width: "100%" }) }} onChange={(val) => { handleChange(element.name, val); }} isSearchable={true} isMulti={element.multiple} required={element.required} name={element.name} options={element.options.map((option, index) => { return { value: option.value, label: option.text } })} />
                                                </div>)
                                        }

                                        if (element.element == "subtitle") {
                                            return (
                                                <h2 key={index}>{element.label}</h2>
                                            )
                                        }
                                    })}
                                </div>
                            })}

                        </div>
                        <div className="modalFooter">
                            <button type="submit">{info.buttonText}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );

};

export default Modal;
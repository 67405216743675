import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'infoGeneral',
  initialState: {
    value: {
      customerType : [],
      customerTypeSelectOptions:[],
      documentType: [],
      documentTypeSelectOptions:[],
      role:[],
      status:[],
      error: ""
    },
  },
  reducers: {
    setInfoGeneral: (state, info) => {
      state.value.customerType = info.payload.customerType;
      state.value.customerTypeSelectOptions = info.payload.customerType.map((customerType, index)=>{return {value:index+1, text:customerType.name}});
      state.value.documentType = info.payload.documentType;
      state.value.documentTypeSelectOptions = info.payload.documentType.map((documentType, index)=>{return {value:index+1, text:documentType.name}});
      state.value.role = info.payload.role;
      state.value.status = info.payload.status;
    },
    deleteInfoGeneral: (state) => {
      state.value.customerType = [];
      state.value.customerTypeSelectOptions = [];
      state.value.documentType = [];
      state.value.documentTypeSelectOptions = [];
      state.value.role = [];
      state.value.status = [];
    },
    setError: (state, info) => {
      state.value.error = info.payload;
    },
    deleteError: (state) =>{
      state.value.error = "";
    }
  }
})

// Action creators are generated for each case reducer function
export const { setInfoGeneral, deleteInfoGeneral, setError, deleteError } = counterSlice.actions

export default counterSlice.reducer
import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'infoUsuario',
  initialState: {
    value: {
      customerId : null,
      email: "",
      firstName:"",
      lastName:"",
      phone: "",
      role:{},
      token: null
    },
  },
  reducers: {
    setInfoUsuario: (state, info) => {
      state.value.customerId = info.payload.user.customerId;
      state.value.email = info.payload.user.email;
      state.value.firstName = info.payload.user.firstName;
      state.value.lastName = info.payload.user.lastName;
      state.value.phone = info.payload.user.phone;
      state.value.role = info.payload.user.role;
      state.value.token = info.payload.token;
    },
    deleteInfoUsuario: (state) => {
      state.value.customerId = null;
      state.value.email = "";
      state.value.firstName = "";
      state.value.lastName = "";
      state.value.phone = "";
      state.value.role = {};
      state.value.token = null;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setInfoUsuario, deleteInfoUsuario } = counterSlice.actions

export default counterSlice.reducer
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Api from '../api.js';
import Select from 'react-select';
import { useLocation } from "react-router-dom";

const Modal = ({ info, sendToRender }) => {
    let api = new Api();
    let infoUsuario = useSelector((state) => state.infoUsuario.value);
    let obj = useRef([]);
    let [alert, setAlert] = useState({ text: "", style: "alertHidden" });
    let [isLoading, setIsLoading] = useState(false);
    //1
    let oh = {};
    info.fields.forEach((row) => {
        row.elements.forEach((element) => {
            if (element.element == "select") {
                oh[element.name] = [];
            }
        })
    })
    let [selects, setSelects] = useState(oh);
    let location = useLocation();

    const cleanForm = () => {
        setAlert({ text: "", style: "alertHidden" });
        for (const key in obj.current) {
            obj.current[key].value = "";
        }

        let newSelects = {};
        for (const key in selects) {
            newSelects[key] = [];
        }
        setSelects(newSelects);

    }

    const handleForm = async (event) => {
        event.preventDefault();

        let data = {};
        let resp;
        info.fields.forEach((row) => {
            row.elements.map((element) => {
                if (element.element == "input") {
                    if (element.isNumber) {
                        data[element.name] = parseInt(obj.current[element.name].value);
                    } else {
                        if (element.type == "email") {
                            data[element.name] = obj.current[element.name].value.toLowerCase();
                        } else {
                            data[element.name] = obj.current[element.name].value;
                        }
                    }

                }

                if (element.element == "select") {
                    if (element.multiple) {
                        if (element.isNumber) {
                            data[element.name] = selects[element.name].map((val) => { return parseInt(val.value) });
                        } else {
                            data[element.name] = selects[element.name].map((val) => { return val.value });
                        }
                    } else {
                        if (element.isNumber) {
                            data[element.name] = selects[element.name].value;
                        } else {
                            if (selects[element.name] && selects[element.name].value !== undefined) {
                                data[element.name] = selects[element.name].value;
                            } else {
                                data[element.name] = "";
                            }
                            
                        }
                    }


                }
            })
        })

        if (info.dataMask != null) {
            data = convertObject(data, info.dataMask);
        }

        if (info.method == "POST") {
            resp = await api.post(info.endpoint, data, infoUsuario.token);
        } else {
            resp = await api.put(info.endpoint, data, infoUsuario.token);
        }


        if (!resp.error) {
            setAlert({ text: "Guardado", style: "alertSuccess" });
            sendToRender?.();

        } else {
            setAlert({ text: resp.error, style: "alertError" });
        }

    }

    const convertObject = (obje, newStructure) => {
        // create a new empty object to store the converted data
        const convertedObj = {};

        // loop through the keys in the new structure
        for (const key of Object.keys(newStructure)) {
            // get the value for the current key in the new structure
            const value = newStructure[key];

            // check if the value is a string
            if (typeof value === 'string') {
                // if the value is a string, get the corresponding value from the original object
                convertedObj[key] = obje[value];
            } else if (Array.isArray(value)) {
                // if the value is an array, it should contain a list of keys to extract from the original object
                convertedObj[key] = value.map(v => obje[v]);
            } else {
                // if the value is an object, it represents a nested structure to convert
                convertedObj[key] = convertObject(obje, value);
            }
        }

        return convertedObj;
    }



    useEffect(() => {
        cleanForm();
        if (infoUsuario.token !== null && info.preLoad) {
            traer();
            setIsLoading(true);
        }
    }, [location]);

    useEffect(() => {
        cleanForm();
        if (infoUsuario.token !== null && info.preLoad) {
            traer();
            setIsLoading(true);
        }

        if (infoUsuario.token !== null && info.preLoad) {
            //preloadValues();
        }

    }, [info]);

    const preloadValues = () => {
        let newSelects = {};
        info.fields.forEach((row) => {
            row.elements.map((element) => {
                if (element.hasOwnProperty("value")) {
                    if (element.element == "input") {
                        obj.current[element.name].value = element.value
                    }
                    if (element.element == "select") {

                        let ids = element.options.filter((opt) => { return opt.value == element.value }).map((s) => { return { value: s.value, label: s.text } });
                        if (element.multiple) {
                            newSelects[element.name] = ids;
                        } else {
                            newSelects[element.name] = ids[0];
                        }
                    }

                }
            })
        })
        setSelects(newSelects);
    }


    async function traer() {
        var resp = await api.get(info.endpoint, infoUsuario.token);
        let huboError = false;

        let newSelects = {};
        if (!resp.error) {
            setIsLoading(false);
            if (info.loadFunction) {
                resp.info = info.loadFunction(resp.info);
            }

            info.fields.forEach((row) => {
                row.elements.map((element, index) => {
                    if (element.element == "input") {
                        if(!element.hasOwnProperty("mask")){
                            obj.current[element.name].value = resp.info[element.name];
                        }else{console.log("entroooo")
                            obj.current[element.name].value = element.mask(resp.info);
                        }
                        
                    }
                    if (element.element == "select") {
                        if (element.options.length == 0) {
                            huboError = true;
                        }
                        let ids = element.options.filter((opt) => {
                            if (Array.isArray(resp.info[element.name])) {
                                return resp.info[element.name].indexOf(opt.value) > -1
                            } else {
                                return opt.value == resp.info[element.name]
                            }

                        }).map((s) => { return { value: s.value, label: s.text } });

                        if (element.multiple) {
                            newSelects[element.name] = ids;
                        } else {
                            newSelects[element.name] = ids[0];
                        }
                    }
                })

            });
            //5
            if (!huboError) {
                setSelects(newSelects);
            }

        }
    }

    async function handleChange(name, val) {
        setSelects({ ...selects, [name]: val });
    }

    return (
        <>
            <form onSubmit={handleForm}>
                <p className={"alert " + alert.style}>{alert.text}</p>

                {info.fields.map((row, indexRow) => {
                    return <div key={indexRow} className="row">
                        {row.elements.map((element, index) => {
                            if (element.element == "input") {
                                return (
                                    <div key={index} className="inputGroup" style={element.hasOwnProperty("hidden") ? { display: "none" } : { display: "flex" }}>
                                        <label>{element.label}</label>
                                        <input key={index} type={element.type} name={element.name} ref={(elementRef) => obj.current[element.name] = elementRef} required={element.required} />
                                    </div>)
                            }

                            if (element.element == "select") {

                                return (
                                    <div key={index} className="inputGroup" style={element.hasOwnProperty("hidden") ? { display: "none" } : { display: "flex" }}>
                                        <label>{element.label}</label>

                                        <Select key={index} styles={{ container: (base) => ({ ...base, width: "100%" }) }} value={selects[element.name]} onChange={(val) => { handleChange(element.name, val); }} isSearchable={true} isMulti={element.multiple} required={element.required} name={element.name} options={element.options.map((option, index) => { return { value: option.value, label: option.text } })} />
                                    </div>)
                            }

                            if (element.element == "subtitle") {
                                return (
                                    <h2 key={index}>{element.label}</h2>
                                )
                            }
                        })}
                    </div>
                })}


                <div className="row">
                    <button type="submit">{info.buttonText}</button>
                </div>
            </form>
        </>
    );

};

export default Modal;
import { GlobalLoading, showLoading } from 'react-global-loading';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import store from './store/store'
import { Provider } from 'react-redux'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setInfoEntidad } from './store/infoEntidad.js'
import { setInfoGeneral } from './store/infoGeneral.js'
import { setInfoUsuario } from './store/infoUsuario.js'

//CSS----------------------------------------------------------------
import './css/App.css';
import './css/Header.css';
import './css/Menu.css';
import './css/Login.css';
import './css/Pages.css';
import './css/Tables.css';
import './css/Forms.css';

//LAYOUTS--------------------------------------------------------------
import LoginLayout from "./pages/layouts/Login";
import DashboardLayout from "./pages/layouts/Dashboard";

//LOGINS---------------------------------------------------------------
import AdminLogIn from "./pages/logins/AdminLogin";
import PacienteLogIn from "./pages/logins/PacienteLogIn";
import RecuperarClave from "./pages/logins/RecuperarClave";
import NuevaClave from "./pages/logins/NuevaClave";
import Logout from "./pages/logins/Logout";
import Rerouter from "./pages/logins/Rerouter";

//SUPER ADMIN----------------------------------------------------------
import Clientes from "./pages/superAdmin/clientes";

//MANEJO USUARIOS------------------------------------------------------
import MiCuenta from "./pages/manejoUsuarios/miCuenta";

//Configuracion Centros------------------------------------------------
import Sedes from "./pages/configuracionCentro/sedes";
import Empleados from "./pages/configuracionCentro/empleados";
import TipoExamenes from "./pages/configuracionCentro/tipoExamenes";
import TipoExamen from "./pages/configuracionCentro/tipoExamen";

//Entidades-----------------------------------------------------------
import Clinicas from "./pages/entidades/clinicas";
import Clinica from "./pages/entidades/clinica";
import Doctores from "./pages/entidades/doctores";
import Doctor from "./pages/entidades/doctor";
import Pacientes from "./pages/entidades/pacientes";

//Ordenes------------------------------------------------------------
import Ordenes from "./pages/ordenes/ordenes";
import PerfilOrden from "./pages/ordenes/orden"
import NuevaOrden from "./pages/ordenes/nuevaOrden"
import EditarOrden from "./pages/ordenes/editarOrden"
import Examen from "./pages/ordenes/examen"

export default function App() {

  const infoUsuario = useSelector((state) => state.infoUsuario.value);

  const dispatch = useDispatch();


  const [isLoggedIn, setIsLoggedIn] = useState(null);

  if (isLoggedIn == null) {
    if (infoUsuario.token == null) {
      //Revisamos si hay un token guardado en localstorage
      if (localStorage.getItem('usuario') !== null) {
        //DEBEMOS GUARDARLO EN INFOUSUARIO
        dispatch(setInfoUsuario(JSON.parse(localStorage.getItem("usuario"))));
        //GUARDAR GENERAL INFO
        dispatch(setInfoGeneral(JSON.parse(localStorage.getItem("general"))));
        //GUARDAR ENTIDAD INFO
        dispatch(setInfoEntidad(JSON.parse(localStorage.getItem("entidad"))));
        setIsLoggedIn(true);
      } else {
        //NAVEGAR a la pagina
        setIsLoggedIn(false);
      }
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        {/*-------------------------------Log Ins----------------------------------------------------------------------------------------------------------------------------*/}


        <Route path="/" element={<LoginLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<AdminLogIn setIsLoggedIn={setIsLoggedIn} />} />
        </Route>


        <Route path="/misResultados" element={<LoginLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<PacienteLogIn setIsLoggedIn={setIsLoggedIn} />} />
        </Route>


        <Route path="/recuperarClave" element={<LoginLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<RecuperarClave />} />
        </Route>


        <Route path="/nuevaClave/:newPassWordToken" element={<LoginLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<NuevaClave />} />
        </Route>


        <Route path="/logout" element={<Logout setIsLoggedIn={setIsLoggedIn} />}></Route>


        <Route path="/rerouter" element={<Rerouter isLoggedIn={isLoggedIn} />}></Route>


        {/*-------------------------------Super Admin-------------------------------------------------------------------------------------------------------------------------*/}

        <Route path="/clientes" element={<DashboardLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<Clientes />} />
          <Route path=":urlCall" element={<Clientes />} />
          <Route path=":urlCall/:id" element={<Clientes />} />
        </Route>


        {/*-------------------------------Manejo Usuario---------------------------------------------------------------------------------------------------------------------*/}


        <Route path="/miCuenta" element={<DashboardLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<MiCuenta />} />
        </Route>


        {/*-------------------------------Configuracion Centro---------------------------------------------------------------------------------------------------------------*/}

        <Route path="/sedes" element={<DashboardLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<Sedes />} />
          <Route path=":urlCall" element={<Sedes />} />
          <Route path=":urlCall/:id" element={<Sedes />} />
        </Route>


        <Route path="/empleados" element={<DashboardLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<Empleados />} />
          <Route path=":urlCall" element={<Empleados />} />
          <Route path=":urlCall/:id" element={<Empleados />} />
        </Route>


        <Route path="/tipoExamenes" element={<DashboardLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<TipoExamenes />} />
          <Route path=":urlCall" element={<TipoExamenes />} />
          <Route path=":profile/:id" element={<TipoExamen />} />
          <Route path=":profile/:id/:urlCall" element={<TipoExamen />} />
          <Route path=":profile/:id/:urlCall/:idProcess" element={<TipoExamen />} />
        </Route>


        {/*-------------------------------Entidades--------------------------------------------------------------------------------------------------------------------------*/}

        <Route path="clinicas" element={<DashboardLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<Clinicas />} />
          <Route path=":urlCall" element={<Clinicas />} />
          <Route path="profile/:id" element={<Clinica />} />
          <Route path="profile/:id/:urlCall" element={<Clinica />} />
          <Route path=":urlCall/:id" element={<Clinicas />} />
        </Route>


        <Route path="/doctores" element={<DashboardLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<Doctores />} />
          <Route path=":urlCall" element={<Doctores />} />
          <Route path="profile/:id" element={<Doctor />} />
          <Route path="profile/:id/:urlCall" element={<Doctor />} />
          <Route path=":urlCall/:id" element={<Doctores />} />
        </Route>

        <Route path="/pacientes" element={<DashboardLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<Pacientes />} />
          <Route path=":urlCall" element={<Pacientes />} />
          <Route path=":urlCall/:id" element={<Pacientes />} />
        </Route>


        {/*-------------------------------Ordenes----------------------------------------------------------------------------------------------------------------------------*/}


        <Route path="/ordenes" element={<DashboardLayout isLoggedIn={isLoggedIn} />}>
          <Route index element={<Ordenes />} />
          <Route path="filtrar/:patientId" element={<Ordenes />} />
          <Route path="nueva" element={<NuevaOrden />} />
          <Route path="editar/:id" element={<EditarOrden />} />
          <Route path="nueva/:urlCall" element={<NuevaOrden />} />
          <Route path=":id" element={<PerfilOrden />} />
          <Route path=":idOrden/examen/:idExamen" element={<Examen />} />
        </Route>


      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
    <GlobalLoading
      loadingColor='#C4DAFA'
      backgroundColor='rgba(0,0,0,0.2)'
      loadingBackgroundColor='#005187'
    />
  </Provider>
);

// Azul menú C4DAFA
// Letras menú sin seleccionar 606060
// Títulos del menú 005187
// Botones 005187
// Interlinea en las tablas de estudios y pacientes EBF3FC
// Elemento seleccionado del menú FFFFFF
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from 'react-redux'

const LoginLayout = ({ isLoggedIn }) => {

  const navigate = useNavigate();

  const infoEntidad = useSelector((state) => state.infoEntidad.value);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/rerouter");
    }
  });

  return (
    <>
      <div id="loginPanel">
        <div id="leftLoginPanel">
          <Outlet />
        </div>
        <div id="rightLoginPanel">
          <img src={infoEntidad.logo} />
        </div>
      </div>
    </>
  )
};

export default LoginLayout;
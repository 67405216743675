import React, { useRef, useState, useEffect } from 'react';
import Api from '../../api.js';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Link, useLocation, useParams } from "react-router-dom";
import SelectSearch from 'react-select-search';
import { faCirclePlay, faCirclePause } from "@fortawesome/free-solid-svg-icons";

import { setError, deleteError } from '../../store/infoGeneral.js'
import Tabla from '../../components/Tabla2.js';

const Nuevo = () => {
    library.add(faCirclePlay);
    library.add(faCirclePause);
    let api = new Api();

    const dispatch = useDispatch();
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    let infoUsuario = useSelector((state) => state.infoUsuario.value);
    let location = useLocation();

    let { idOrden, idExamen } = useParams();
    let [examen, setExamen] = useState("");
    let [paciente, setPaciente] = useState("");
    let [loading, setLoading] = useState(false);
    let [cantidad, setCantidad] = useState(0);
    let [procesos, setProcesos] = useState([]);
    let [ordenCompletada, setOrdenCompletada] = useState(false);
    let [visorVisibility, setVisorVisibility] = useState("hiddenModal");
    let [imgSrc, setImgSrc] = useState("");
    let [imgDisplay, setImgDisplay] = useState("none");
    let [videoSrc, setVideoSrc] = useState("");
    let [videoDisplay, setVideoDisplay] = useState("none");
    let [pdfSrc, setPdfSrc] = useState("");
    let [pdfDisplay, setPdfDisplay] = useState("none");

    const [selectedFile, setSelectedFile] = useState([]);

    const [isDicomFile, setIsDicomFile] = useState(false);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files);
    }


    const handleIsDicomFileChange = (event) => {
        setIsDicomFile(event.target.checked);
        console.log(event.target.checked, 'sadsda');
        // setIsDicomFile(event.target.checked);
        console.log(isDicomFile)
    }

    useEffect(() => {
        if (infoUsuario.token !== null) {
            traerOrden();
        }
    }, [location, infoUsuario]);


    async function traerOrden() {
        var info = await api.get("order/" + idOrden + "/detail/" + idExamen, infoUsuario.token);

        if (!info.error) {
            setExamen("Nombre Examen");
            setPaciente(info.info.patientId);
            setCantidad(info.info.quantity);
            setProcesos(info.info.orderDetailProcesses.filter((pro) => {
                return pro.process.statusId == 1;
            }));

            if (info.info.statusId == 4) {
                setOrdenCompletada(true);
            }
        }
    }

    const submitForm = async (event) => {
        event.preventDefault();

        if (loading) {
            return;
        }

        setLoading(true);

        if (selectedFile.length == 0) {
            dispatch(setError("Debe seleccionar al menos un archivo"));
            return;
        }

        var resp = await api.postFile("order/" + idOrden + "/detail/" + idExamen + "/file", selectedFile, infoUsuario.token, isDicomFile);

        if (!resp.error) {
            setLoading(false);
            sendToRender();
            dispatch(deleteError());
            alert("Creado");
        } else {
            setLoading(false);
            dispatch(setError(resp.error));
        }
    }

    const completado = async () => {
        var resp = await api.put("order/" + idOrden + "/complete", [], infoUsuario.token);

        if (!resp.error) {
            dispatch(deleteError());
            alert("Terminado");
        } else {
            dispatch(setError(resp.error));
        }
    }

    async function iniciarProceso(idProceso) {
        var resp = await api.post("order/" + idOrden + "/detail/" + idExamen + "/process/" + idProceso + "/start", {}, infoUsuario.token);

        if (!resp.error) {
            traerOrden();
        } else {
            dispatch(setError(resp.error));
        }
    }

    async function detenerProceso(idProceso, idProcesoActual) {
        var resp = await api.put("order/" + idOrden + "/detail/" + idExamen + "/process/" + idProceso + "/stop/" + idProcesoActual, {}, infoUsuario.token);

        if (!resp.error) {
            traerOrden();
        } else {
            dispatch(setError(resp.error));
        }
    }

    function readableDate(d) {
        var date = new Date(d);
        return date.toISOString().substring(0, 10);
    }

    const openVisor = (tipo, archivo, id = null) => {
        if (tipo.split("/")[0] == "image") {
            setImgSrc(archivo);
            setImgDisplay("block");
            setPdfDisplay("none");
            setVideoDisplay("none");
            setVisorVisibility("visibleModal");
        }

        if (tipo == "video/mp4") {
            setVideoSrc(archivo);
            setImgDisplay("none");
            setVideoDisplay("block");
            setPdfDisplay("none");
            setVisorVisibility("visibleModal");
        }

        if (tipo == "application/pdf") {
            setImgDisplay("none");
            setPdfDisplay("block");
            setVideoDisplay("none");
            setPdfSrc(archivo);
            setVisorVisibility("visibleModal");
        }

        if (tipo == "application/octet-stream" || tipo == "application/dicom" || tipo.includes("stl")) {
            setImgDisplay("none");
            setPdfDisplay("block");
            if (archivo.startsWith('[')) {
                setPdfSrc(`https://dcmviewrv2.dentyview.com/localbasic?id=${id}&token=${JSON.parse(localStorage.usuario).token}`);
                // setPdfSrc(`http://localhost:3000/localbasic?id=${id}&token=${JSON.parse(localStorage.usuario).token}`);
                // setPdfSrc(`/dist/localbasic?id=${id}&token=${JSON.parse(localStorage.usuario).token}`);
            } else {
                let fileExtension = archivo.split(".");
                fileExtension = fileExtension[fileExtension.length - 1].toLowerCase();

                switch (fileExtension) {
                    case 'stl':
                        const stlUrl = "https://stlviewr.dentyview.com?url=" + archivo;
                        setPdfSrc(stlUrl);
                        break;
                    case 'ply':
                        const plyUrl = "https://plyviewer.dentyview.com?file=" + archivo;
                        setPdfSrc(plyUrl);
                        break;
                    default:
                        setPdfSrc(`https://dcmviewrv2.dentyview.com/localbasic?url=${archivo}`);
                        break;
                }

                // if (fileExtension == "stl") {
                //     let stlUrl = "https://stlviewr.dentyview.com?url=" + archivo;
                //     setPdfSrc(stlUrl);
                // } else {
                //     // setPdfSrc("https://dcmviewr.dentyview.com/tests/pacs/viewer.html?" + archivo);
                //     setPdfSrc(`https://dcmviewrv2.dentyview.com/localbasic?url=${archivo}`);
                // }
            }

            setVisorVisibility("visibleModal");
        }
    }

    const closeModal = () => {
        setVisorVisibility("hiddenModal");
        setImgSrc("");
        setVideoSrc("");
        setPdfSrc("");
    }

    async function eliminarImagen(idImagen) {

        var info = await api.delete("order/" + idOrden + "/detail/" + idExamen + "/file/" + idImagen, infoUsuario.token);

        if (!info.error) {
            window.location.reload();
        }
    }


    let error = '';
    if (infoGeneral.error.length) {
        error = <p className="error">{infoGeneral.error}</p>;
    }

    const customer = JSON.parse(localStorage.getItem('entidad'));
    const user = JSON.parse(localStorage.getItem('usuario'));
    const showExternalFilesFeature = !!customer.externalFilesLink;
    console.log(customer, showExternalFilesFeature);
    const tabla1 = {
        endpoint: "order/" + idOrden + "/detail/" + idExamen,
        infoInKey: "orderDetailFiles",
        titulos: [{ texto: "Archivo", name: "" }, { texto: "Tipo", name: "" }, { texto: "Descargar", name: "" }, { texto: "", name: "" }],
        columnas: [
            { prop: "filename", type: "boldText", openVisor: true },
            { prop: "filename", type: "text", format: (x) => { if (x.split("/")[0] == "image") { return "Imagen"; } else if (x.includes("pdf")) { return "Pdf"; } else if (x.includes("stl")) { return "Stl"; } else { return "Dicom"; } } },
            { prop: "file", type: "link", urlProp: "file", placeholder: "Descargar" },
            { prop: "id", type: "func", func: (x) => { eliminarImagen(x); }, placeholder: ordenCompletada ? '' : 'Eliminar' }]
    }

    const tabla2 = {
        endpoint: "order/" + idOrden + "/detail/" + idExamen + "/external-files",
        titulos: [
            { texto: "Archivo por Validar", name: "" }],
        columnas: [
            {
                prop: "file", type: "link", customUrl: "file", text: 'filename', format: (x) => {
                    console.log(x);
                    return `${customer.externalFilesLink}/${x.file}?id=${x.id}&order=${idOrden}&detail=${idExamen}&patient=${x.patientId}&token=${user.token}`
                }
            }
        ]
    }

    let [forceRender, setForceRender] = useState(0);
    const sendToRender = () => {
        setForceRender(forceRender + 1);
    };
    return (
        <>
            <div id="curtain" className={visorVisibility}>
                <div className={"modal " + visorVisibility} style={{ width: "94%" }}>
                    <div className="modalHeader">
                        <h2>Visor</h2>
                        <button className="closeModalBtn" onClick={closeModal}>x</button>
                    </div>
                    <div className="modalBody modalBgBlack" style={{ width: "100%", padding: "0px" }}>
                        <div style={{ display: imgDisplay, width: "100%", textAlign: "center" }}>
                            <img style={{ maxWidth: "100%", maxHeight: "100%" }} src={imgSrc} />
                        </div>
                        <div style={{ display: videoDisplay, width: "100%", textAlign: "center" }}>
                            <video style={{ maxWidth: "100%", maxHeight: "100%" }} key={videoSrc} controls>
                                <source src={videoSrc} type="video/mp4" />
                            </video>
                        </div>
                        <div style={{ display: pdfDisplay, width: "100%", height: "700px", minHeight: "300px" }}>
                            <iframe style={{ width: "100%", height: "100%" }} src={pdfSrc}></iframe><div id="test"></div>
                            {/* <div dangerouslySetInnerHTML={{ __html: `<iframe src='${pdfSrc}' />` }} /> */}
                        </div>
                    </div>
                    <div className="modalFooter">
                    </div>
                </div>
            </div>
            <div className="pageHeading">
                <h1><FontAwesomeIcon icon="fa-clipboard" /> Detalle Orden</h1>
            </div>
            {error}

            <div className="panel" style={infoUsuario.role.name == "PATIENT" || ordenCompletada ? { display: "none" } : { display: "flex" }}>
                {procesos.map((pro) => {
                    let btn = '';
                    let historyLog = '';
                    if (pro.orderDetailProcessLogs.length == 0) {
                        btn = <button className="play" onClick={() => { iniciarProceso(pro.process.id) }}><FontAwesomeIcon icon="fa-circle-play" /></button>
                        historyLog = <h5>-</h5>;
                    } else if (pro.orderDetailProcessLogs[pro.orderDetailProcessLogs.length - 1].end !== null) {
                        btn = <button className="play" onClick={() => { iniciarProceso(pro.process.id) }}><FontAwesomeIcon icon="fa-circle-play" /></button>
                        historyLog = <h5>{pro.orderDetailProcessLogs[pro.orderDetailProcessLogs.length - 1].start.split("T")[1]}-{pro.orderDetailProcessLogs[pro.orderDetailProcessLogs.length - 1].end.split("T")[1]}</h5>;
                    } else {
                        btn = <button className="pause" onClick={() => { detenerProceso(pro.process.id, pro.orderDetailProcessLogs[pro.orderDetailProcessLogs.length - 1].id) }}><FontAwesomeIcon icon="fa-circle-pause" /></button>
                        historyLog = <h5>{pro.orderDetailProcessLogs[pro.orderDetailProcessLogs.length - 1].start.split("T")[1]}-</h5>;
                    }
                    //No debe poderse editar procesos si la orden ya esta completada
                    if (ordenCompletada) {
                        btn = "";
                    }
                    return <div className="process"><h4>{pro.process.name}</h4>{historyLog}{btn}</div>
                })}
            </div>

            <form onSubmit={submitForm} style={infoUsuario.role.name == "PATIENT" || ordenCompletada ? { display: "none", marginBottom: "100px" } : { display: "block", marginBottom: "100px" }}>
                <div className="row">
                    <h2>Subir Examen</h2>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Archivo</label>
                        <input type="file" onChange={handleFileChange} multiple="multiple" />
                        <label className='bold'>
                            <input type="checkbox" value={isDicomFile} onChange={handleIsDicomFileChange}></input>Selecciona esta opción para tomografías
                        </label>
                    </div>
                </div>
                <div className="row">
                    <button type="submit">{loading ? "Cargando......." : "Subir Archivo"}</button>
                    <button type="button" style={{ marginLeft: "20px" }} onClick={completado}>Marcar como Terminado</button>
                </div>

            </form>
            <button type="button" style={!showExternalFilesFeature || ["PATIENT", "CLINIC", "DOCTOR"].indexOf(infoUsuario.role.name) > -1 ? { display: "none" } : { marginBottom: "30px", float: "left", marginLeft: "13px", }} onClick={(e) => {
                e.preventDefault()
                console.log(customer.externalFilesLink);
                window.open(`${customer.externalFilesLink}?order=${idOrden}&detail=${idExamen}&patient=${paciente}&token=${user.token}`, '_blank')
            }}>Crear formato externo</button>
            <Tabla info={tabla2} noQueries={true} forceRender={forceRender} style={!showExternalFilesFeature || infoUsuario.role.name == "PATIENT" ? { display: "none" } : { display: "block" }} />

            <Tabla info={tabla1} forceRender={forceRender} openVisor={openVisor} />
        </>
    );

};

export default Nuevo;
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Tabla from '../../components/Tabla2.js';

const Modulo = () => {
    let infoGeneral = useSelector((state) => state.infoGeneral.value);

    //Configurables---------------------------------------------------------------------------
    const titulo = "Tipo de Examenes";
    const icono = "fa-clipboard";
    const modals = [
        {
            titulo: "Nuevo Tipo Examen",
            endpoint: "file-type",
            buttonText: "Guardar",
            urlCall:"new",
            method:"POST",
            fields: [
                {label:"row", elements:[
                  {label:"Nombre", name:"name", element:"input", type:"text", required:true, isNumber:false},
                  {label:"Precio", name:"price", element:"input", type:"number", required:true, isNumber:true}
                ]}
              ],
            dataMask:null
        },
        /*{
            titulo: "Editar Tipo Examen",
            endpoint: "file-type/",
            buttonText: "Editar",
            urlCall:"edit",
            method:"PUT",
            preLoad:true,
            fields: [
                {label:"row", elements:[
                  {label:"Nombre", name:"name", element:"input", type:"text", required:true, isNumber:false},
                  {label:"Precio", name:"price", element:"input", type:"number", required:true, isNumber:true}
                ]}
              ],
            dataMask:null
        }*/
        ];
    const tabla1 = {
        endpoint: "file-type",
        titulos: [{texto:"Nombre", name:"firstName"}, {texto:"Precio Base", name:"price"}, {name:"status"}],
        columnas: [{prop:"name", type:"link", url:"profile/$id$"}, {prop:"price", format:(x)=>{return "$"+x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");}, type:"text"}, {type:"status", url:"file-type"}]
    }

    //Funciones Basicas-----------------------------------------------------------------------
    let { urlCall } = useParams();
    let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal)=>{if(modal.urlCall != urlCall){return "hiddenModal"}else{return "visibleModal"}}));
    const navigate = useNavigate();
    
    let location = useLocation();

    const openModal = (modalNumber)=>{
        navigate(modals[modalNumber].urlCall);
    }
    let [forceRender, setForceRender] = useState(0);
    const sendToRender = ()=>{
        setForceRender(forceRender + 1);
    };

    useEffect(()=>{
        setModalsVisibility(modals.map((modal)=>{if(modal.urlCall != urlCall){return "hiddenModal"}else{return "visibleModal"}}));
    }, [location]);
    

    return (
    <>
        {modals.map((modal, index) =>{
            return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender}/>
        })}
        
        
        
        <div className="pageHeading">
            <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
            <Link to="new">+</Link>
        </div>

        

        <Tabla info={tabla1} forceRender={forceRender}/>
        
    </>
    );
    
};
  
export default Modulo;